import React, { useContext, useEffect, useState } from 'react';
import {
  CanAccess,
  IResourceComponentsProps,
  useApiUrl,
  useCan,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';

import {
  AntDesignOutlined,
  UserOutlined,
  SolutionOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Edit,
  Form,
  Input,
  ListButton,
  RcFile,
  Select,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  useFileUploadState,
  useForm,
  DatePicker,
  Row,
  Col,
  Card,
  Avatar,
  Space,
} from '@pankod/refine-antd';
import InputMask from 'react-input-mask';
import 'react-mde/lib/styles/css/react-mde-all.css';

import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { Role } from '../../enums/role';
import { FormRule, InputProps, InputRef } from 'antd';
import { axiosInstance } from '../../App';
import { UserType } from '../../enums/user.type';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { DocumentType } from '../../enums/document.type';
import { uuidV4 } from 'utility/uuidv4';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserContext } from 'UserProvider';

dayjs.extend(customParseFormat);
const { Text } = Typography;
const files: string[] = [];

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const { onChange: onChangeAux } = useFileUploadState();
  const { onChange: onChangeNbki } = useFileUploadState();
  const { list } = useNavigation();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();
  const sendCode = async (values: any) => {
    if (notify) {
      setRegButton(true);
      let code;
      try {
        code = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/profile/send/code',
          {
            phone: phone ? phone : me?.phone,
          },
        );
        setTimeout(() => setSendDisabled(false), 30000);
        setSaveDisabled(false);
        notify({
          description: 'SMS с кодом отправлено',
          message:
            process.env.REACT_APP_PROD === '1'
              ? 'Код отправлен на указанный номер мобильного телефона'
              : 'Тестовый режим. Код: ' + code.data,
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
      } catch (e) {
        setRegButton(false);
        notify({
          description: 'Ошибка отправки кода',
          message:
            'Убедитесь что телефон введен правильно, возможно такой телефон уже зарегистрирован на платформе',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const [phone, setPhone] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [regButton, setRegButton] = useState<boolean>(false);
  const [currentCode, setCurrentCode] = useState<boolean>(false);
  const [sendDisabled, setSendDisabled] = useState<boolean>(false);
  const [reason, setReason] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const { onChange } = useFileUploadState();

  let publicFileList: UploadFile<any>[] | undefined;
  let record: any;

  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const {
    formProps: props,
    saveButtonProps: saveProps,
    queryResult,
    form,
  } = useForm<IUser>({
    onMutationSuccess: () => {
      return true;
    },
    successNotification: {
      description: 'Успешно',
      message: 'Пользователь обновлен',
      type: 'success',
    },
  });

  let formValues;

  if (me) {
    formValues = {
      email: me.email,
      firstName: me.firstName,
      middleName: me.middleName,
      lastName: me.lastName,
      role: me.role,
      dateOfBirth: me.dateOfBirth,
      phone: me.phone,
      stars: me.stars,
      percent: me.percent,
    };
  }

  let formData, dirInitValues, avatar, isReady;
  if (queryResult?.isSuccess) {
    formData = queryResult.data.data;
    avatar = formData?.pictures;
    isReady = true;
    dirInitValues = {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      role: formData.role,

      stars: formData.stars,
      percent: formData.percent,
      password: '',
      dateOfBirth: formData?.dateOfBirth ? dayjs(formData?.dateOfBirth) : null,
    };
  }

  const admin = useCan({ resource: 'users', action: 'admin' });

  return (
    <>
      {formData && me?.uuid !== record?.uuid && (
        <Edit
          headerProps={{
            subTitle: '',
            breadcrumb: <></>,
            extra: <ListButton />,
          }}
          title="Править пользователя"
          saveButtonProps={{ ...saveProps, children: 'Сохранить' }}
        >
          <Row gutter={[12, 12]}>
            <Col xs={4}>
              <Card>
                <Space size={12} direction="vertical" style={{ width: '100%' }}>
                  {!avatar && (
                    <Avatar
                      style={{
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      size={120}
                      shape="square"
                      icon={<UserOutlined />}
                    />
                  )}
                  {avatar && (
                    <Avatar
                      style={{ width: '90%', height: 'auto' }}
                      shape="square"
                      icon={<UserOutlined />}
                      src={process.env.REACT_APP_BACKEND_URL + avatar.path}
                    />
                  )}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Upload
                      name="file"
                      action={`${apiUrl}/media/upload`}
                      headers={{
                        Authorization: `Bearer ${localStorage.getItem(
                          'token',
                        )}`,
                      }}
                      maxCount={1}
                      multiple
                      onRemove={(e) => {
                        // console.log(e);
                      }}
                      showUploadList={false}
                      onChange={(file) => {
                        if (file.file.status === 'done') {
                          location.reload();
                        }
                      }}
                      data={(e) => {
                        const fileUuid = uuidV4();
                        console.log(e);
                        // addOrRemoveItem(files, fileUuid);
                        // form.setFieldsValue({
                        //   files,
                        // });

                        return {
                          fileUuid,
                          type: DocumentType.AVATAR,
                          origName: e.name,
                        };
                      }}
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        style={{ width: '100%' }}
                      >
                        Обновить аватар
                      </Button>
                    </Upload>
                  </div>
                </Space>
              </Card>
            </Col>
            <Col xs={20}>
              <Card>
                <Form
                  {...props}
                  form={form}
                  layout="vertical"
                  initialValues={dirInitValues}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Фамилия"
                    name="lastName"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Имя"
                    name="firstName"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <CanAccess resource="users" action="admin">
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Form.Item
                          label="Должность"
                          name="role"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option
                              value={Role.MANAGER}
                              children={Role.MANAGER}
                            />
                            <Select.Option
                              value={Role.MANAGER_ASIG}
                              children={Role.MANAGER_ASIG}
                            />
                            <Select.Option
                              value={Role.MANAGER_BIG}
                              children={Role.MANAGER_BIG}
                            />
                            <Select.Option
                              value={Role.ACCOUNTANT}
                              children={Role.ACCOUNTANT}
                            />
                            <Select.Option
                              value={Role.ADMIN}
                              children={Role.ADMIN}
                            />
                            <Select.Option
                              value={Role.CONTROL}
                              children={Role.CONTROL}
                            />
                            <Select.Option
                              value={Role.DIRECTOR}
                              children={Role.DIRECTOR}
                            />
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="Рейтинг"
                          name="stars"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input step={0.1} type="number" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="Ставка"
                          name="percent"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input type="number" addonAfter="%" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </CanAccess>

                  <Form.Item
                    label="Сменить телефон"
                    name="phone"
                    rules={[
                      {
                        required: false,
                        type: 'string',
                      },
                    ]}
                  >
                    <InputMask
                      placeholder={'+373********'}
                      mask="+37399999999"
                      // maskChar=' '
                    >
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Edit>
      )}
    </>
  );
};
