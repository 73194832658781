import { ShipStatus } from 'enums/ship.status';

export const getColorShipStatus = (status: ShipStatus): string => {
  switch (status) {
    case ShipStatus.BOOKING:
      return '#ffb900';
    case ShipStatus.CANCEL:
      return '#ff0000';
    case ShipStatus.DONE:
      return '#018d00';
    case ShipStatus.EXPORT:
      return '#494949';
    case ShipStatus.FROZEN:
      return '#ff8383';
    case ShipStatus.PAPERWORK:
      return '#9300ff';
    case ShipStatus.SEA:
      return '#00a2ff';
    default:
      return '#f50';
  }
};
