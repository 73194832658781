import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IUser } from 'interfaces';
import moment from 'moment';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
const { Text } = Typography;
export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IUser>({
    successNotification: {
      description: 'Пользователь создан',
      message: 'Просматривайте пользователей в разделе Пользователи',
      type: 'success',
    },
    errorNotification: {
      description: 'Пользователь не создан',
      message: 'Email или телефон уже заняты',
      type: 'error',
    },
  });
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  const apiUrl = useApiUrl();

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать пользователя"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Имя"
          name="firstName"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastName"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Телефон"
          name="phone"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Электронная почта"
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
            },
            {
              type: 'email',
              message: 'Пожалуйста, введите Ваш email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* пароль */}
        <Form.Item
          name="password"
          label="Придумайте пароль"
          rules={[
            {
              required: true,
              min: 6,
              max: 20,
              message: 'Введите пароль (от 6 до 20 символов)',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Повторите ввод пароля"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, повторите ввод пароля',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Введенные пароли не совпадают'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Тип участника платформы"
          name="role"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: Role.ADMIN,
                value: Role.ADMIN,
              },
              {
                label: Role.ACCOUNTANT,
                value: Role.ACCOUNTANT,
              },
              {
                label: Role.MANAGER,
                value: Role.MANAGER,
              },
              {
                label: Role.MANAGER_ASIG,
                value: Role.MANAGER_ASIG,
              },
              {
                label: Role.MANAGER_BIG,
                value: Role.MANAGER_BIG,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
