import { Languages } from 'enums/languages.type';

const vamas: { [key in Languages.RU | Languages.RO]: string[] } = {
  'Русский язык': [
    'На усмотрение водителя',
    'Могилев-Подольский / Атаки ',
    'Албица / Леушены',
    'Галац / Джурджулешты',
    'Мамалыга / Крива',
    'Бричаны / Росошаны',
    'Джурджулешты / Рени',
    'Костешты / Stinca',
    'Мирное / Табаки',
    'Паланка / Маяки',
    'Первомайск / Кучурган',
    'Тудора / Староказачье',
  ],
  'Limba Românǎ': [
    'La discreția șoferului',
    'Otaci/ Mogilev - Podolsk',
    'Briceni / Rososani',
    'Giurgiulesti / Reni',
    'Giurgiulesti / Galați',
    'Criva / Mamaliga',
    'Costești/Stinca',
    'Leuseni/Albița',
    'Mirnoe/Tabacu',
    'Palanca/Maiaki',
    'Pervomaisc/Cuciurgani',
    'Tudora/Starocazacie',
  ],
};

export default vamas;
