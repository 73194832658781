import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetLocale,
  useNavigation,
  useSetLocale,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { ClientType } from 'enums/client.type';
import { CurrencyType } from 'enums/currency.type';
import { Firms } from 'enums/firms.type';
import { Languages } from 'enums/languages.type';
import { ICompanies, IOffer } from 'interfaces';
import moment from 'moment';
import { off } from 'process';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const checkFirm = (firm: Firms) => {
  switch (firm) {
    case Firms.RCI:
      return 'rci';
    case Firms.REAL:
      return 'real';
    case Firms.RCI_INVEST:
      return 'invest';
    case Firms.RCI_PMR:
      return 'rci_pmr';
    default:
      return 'real';
  }
};
const checkCurrency = (currency: CurrencyType) => {
  switch (currency) {
    case CurrencyType.MDL:
      return 'mdl';
    case CurrencyType.EUR:
      return 'eur';
    case CurrencyType.USD:
      return 'usd';
    case CurrencyType.PMR:
      return 'pmr';
    default:
      return 'mdl';
  }
};

export const InvoiceShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const ids = id?.split('&')[0];
  const type: ClientType =
    ClientType.FIRM === id?.split('&')[1]
      ? ClientType.FIRM
      : ClientType.TRANSPORT;

  const [offerType, setOfferType] = useState<ClientType>(type);
  const [hideBtn, setHideBtn] = useState<boolean>(true);

  const {
    queryResult: { data: response, isLoading, refetch: refetchOffer },
  } = useShow<IOffer>({ resource: 'offers', id: ids });

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (response?.data) {
  //     if (response?.data.language === Languages.RU) {
  //       console.log('ru', response?.data.language);
  //       changeLanguage('ru');
  //       // window.location.reload();
  //     } else {
  //       console.log('ro', response?.data.language);
  //       changeLanguage('ro');
  //       // window.location.reload();
  //     }
  //   }
  // }, [response]);
  if (!response) {
    return <Spin>Загрузка</Spin>;
  }
  const { data } = response;

  const leftSide = [
    {
      title: 'blank.typeData',
      data: [
        { title: 'blank.type', text: data.transportType },
        { title: 'blank.numberCar', text: data.carNumber },
        {
          title: 'blank.driverData',
          text:
            data.driverContact && data.driverContact !== ''
              ? JSON.parse(data.driverContact).map(
                  (contact: { fio: string; phone: string }) => (
                    <span>
                      {contact.fio} {contact.phone}
                    </span>
                  ),
                )
              : '',
        },
        {
          title: 'blank.docs',
          text:
            data.documents && data.documents !== ''
              ? JSON.parse(data.documents).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.documents).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
      ],
    },
    {
      title: 'blank.cargoInfo',
      data: [
        { title: 'blank.mass', text: `${data.mass} ${data.addMass}` },
        { title: 'blank.volum', text: `${data.volum} ${data.addVolum}` },
        { title: 'blank.typeCargo', text: data.characterCargo },
        { title: 'blank.temperature', text: data.thermoMode },
      ],
    },
    {
      title: 'blank.condition',
      data: [
        {
          title: 'blank.srecificalCondition',
          text:
            data.conditions && data.conditions !== ''
              ? JSON.parse(data.conditions).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.conditions).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
        {
          title: 'blank.transportCondition',
          text: t('blank.transportCondition_text', {
            lng: data.language === Languages.RU ? 'ru' : 'ro',
          }),
        },
        {
          title: 'blank.price',
          text: `${Number(
            offerType === ClientType.FIRM ? data.priceFirm : data.priceDriver,
          ).toLocaleString('ru-RU')}  (${
            offerType === ClientType.FIRM
              ? data.textPriceLender
              : data.textPriceDriver
          }) ${
            offerType === ClientType.FIRM
              ? data.currencyFirm
              : data.currencyDriver
          }`,
        },
        {
          title: 'blank.term',
          text: `${
            offerType === ClientType.FIRM
              ? t('blank.perecislenie.firm', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
              : t('blank.perecislenie.transport', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
          } ${
            offerType === ClientType.FIRM
              ? `${data.firmFrom}-${data.firmTo}`
              : `${data.driverFrom}-${data.driverTo}`
          } ${
            offerType === ClientType.FIRM
              ? t('blank.perecislenie2.firm', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
              : t('blank.perecislenie2.transport', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
          }`,
        },
        {
          title: 'blank.contactRCI',
          text: `${data.user.lastName} ${data.user.firstName} ${data.user.phone}`,
        },
      ],
    },
  ];

  const cancelEditPage = () => {
    document.designMode = 'off';
    setHideBtn(true);
  };
  const editPage = () => {
    document.designMode = 'on';
    setHideBtn(false);
  };

  function save(divName: any) {
    const printContents = document.getElementById(divName)?.innerHTML;
    const originalContents = document.body.innerHTML;
    const popupWin = window.open('', '_blank');
    if (divName && printContents && popupWin) {
      popupWin.document.open();
      popupWin.document.write(
        `<html><head ><title>Инвойс №${data.offerNumber}_${offerType}</title><style type="text/css">*{box-sizing:border-box;line-height:1rem;font-size:16px;-webkit-print-color-adjust: exact;} table{border-collapse:collapse} td{font-size: 10px,padding:6px auto} </style></head><body style="box-sizing: border-box;font-family: sans-serif;margin: 0;padding: 0;position: relative">` +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
      popupWin.print();
      // popupWin.onafterprint = function () {
      //   popupWin.close();
      // };
    }
  }

  return !isLoading && data ? (
    <CanAccess resource="offers" action="show">
      <div
        style={{
          overflow: 'auto',
          paddingBottom: '70px',
        }}
      >
        <>
          <Row gutter={[20, 20]}>
            <Col>
              <Space size={12}>
                <Button type="primary" onClick={() => save('printable')}>
                  {t('download.invoice')}
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    offerType === ClientType.FIRM
                      ? setOfferType(ClientType.TRANSPORT)
                      : setOfferType(ClientType.FIRM)
                  }
                >
                  Заявка для{' '}
                  {offerType === ClientType.FIRM
                    ? ClientType.TRANSPORT
                    : ClientType.FIRM}
                </Button>
                {hideBtn ? (
                  <Tooltip title={t('can_edit_only_this_page')}>
                    <Button type="primary" onClick={editPage}>
                      Править
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('cancel_edit_mode')}>
                    <Button type="primary" danger onClick={cancelEditPage}>
                      Выключить режим правок
                    </Button>
                  </Tooltip>
                )}
                <CanAccess resource="offers" action="edit">
                  <Button
                    type="primary"
                    //   style={{ color: '#fff', '&:hover': { color: '#fff' } }}
                  >
                    Изменить
                  </Button>
                </CanAccess>
              </Space>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Progress size={180} />
                </div>
              ) : (
                <div className="printable" id="printable">
                  <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '28.9cm',
                      maxHeight: '28.9cm',
                      backgroundColor: '#ffffff',
                      padding: '0.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      margin: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    {/* ЛОГОТИП */}
                    {offerType === ClientType.FIRM && (
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/img/ico_logo.${checkFirm(data.company)}.png`}
                        alt="Real Cargo Logo"
                        style={{ width: '300px' }}
                      />
                    )}
                    {/* ПЕРВЫЙ БЛОК */}
                    <div
                      style={{
                        marginTop: '16px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography.Text
                        style={{ lineHeight: '1em', fontWeight: 600 }}
                      >
                        Invoice №{data.offerNumber} from{' '}
                        {moment(data.createdAt).format('DD.MM.YYYY')}
                      </Typography.Text>
                      <Typography.Text
                        style={{ lineHeight: '1em', fontWeight: 600 }}
                      >
                        Инвойс №{data.offerNumber} от{' '}
                        {moment(data.createdAt).format('DD.MM.YYYY')}
                      </Typography.Text>
                    </div>
                    {/* Второй блок карточка */}
                    {offerType === ClientType.FIRM ? (
                      <div
                        style={{
                          marginBottom: '16px',
                          border: '1px solid #cfcfcf',
                          borderTop: 0,
                          borderRadius: '5px',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            background: '#001d3f',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#fff',
                            padding: '4px',
                            fontSize: '16px',
                          }}
                        >
                          Beneficiary data / Данные получателя
                        </div>
                        <div>
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                width: '30%',
                                color: '#696969',
                                display: 'flex',
                                padding: '4px',
                                alignItems: 'center',
                                background: '#f2f2f2',
                                borderRight: '1px solid #cfcfcf',
                                borderBottom: '1px solid #cfcfcf',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  lineHeight: '2em',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {'Benificiary\nПолучатель'}
                              </Typography.Text>
                            </div>
                            <div
                              style={{
                                width: '70%',
                                lineHeight: '1em',
                                color: '#696969',
                                padding: '4px',
                                borderBottom: '1px solid #cfcfcf',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  fontWeight: 600,
                                  color: 'rgb(105, 105, 105)',
                                }}
                              >
                                "{data.company}" S.R.L.
                              </Typography.Text>
                              <br />
                              {t(`invoice.${checkFirm(data.company)}.label1`)}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                width: '30%',
                                color: '#696969',
                                display: 'flex',
                                padding: '4px',
                                alignItems: 'center',
                                background: '#f2f2f2',
                                borderRight: '1px solid #cfcfcf',
                                borderBottom: '1px solid #cfcfcf',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  lineHeight: '2em',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {
                                  'Account with institution\nБанковские реквизиты'
                                }
                              </Typography.Text>
                            </div>
                            <div
                              style={{
                                width: '70%',

                                lineHeight: '1em',
                                padding: '4px',
                                color: '#696969',
                                borderBottom: '1px solid #cfcfcf',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {t(
                                `invoice.${checkFirm(
                                  data.company,
                                )}.label2.${checkCurrency(data.currencyFirm)}`,
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                width: '30%',
                                color: '#696969',
                                display: 'flex',
                                padding: '4px',
                                alignItems: 'center',
                                background: '#f2f2f2',
                                borderRight: '1px solid #cfcfcf',
                                borderBottom: '1px solid #cfcfcf',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  lineHeight: '2em',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {'Correspondent bank\nБанк - корреспондент'}
                              </Typography.Text>
                            </div>
                            <div
                              style={{
                                width: '70%',

                                lineHeight: '1em',
                                padding: '4px',
                                color: '#696969',
                                borderBottom: '1px solid #cfcfcf',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {t(
                                `invoice.${checkFirm(
                                  data.company,
                                )}.label3.${checkCurrency(data.currencyFirm)}`,
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginBottom: '16px',
                          border: '1px solid #cfcfcf',
                          borderTop: 0,
                          borderRadius: '5px',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            background: '#001d3f',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#fff',
                            padding: '4px',
                            fontSize: '16px',
                          }}
                        >
                          Beneficiary data / Данные получателя
                        </div>
                        <div>
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                width: '30%',
                                padding: '4px',
                                color: '#696969',
                                display: 'flex',
                                alignItems: 'center',
                                background: '#f2f2f2',
                                borderRight: '1px solid #cfcfcf',
                                borderBottom: '1px solid #cfcfcf',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  lineHeight: '2em',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {'Benificiary\nПолучатель'}
                              </Typography.Text>
                            </div>
                            <div
                              style={{
                                width: '70%',
                                lineHeight: '1em',
                                color: '#696969',
                                borderBottom: '1px solid #cfcfcf',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  fontWeight: 600,
                                  color: 'rgb(105, 105, 105)',
                                }}
                              >
                                {data.offerDriver.orgName}
                              </Typography.Text>
                              <br />
                              {data.offerDriver.rekvizits}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Третий блок карточка */}
                    {offerType === ClientType.FIRM ? (
                      <div>
                        {/* Плательщик */}
                        <div
                          style={{
                            border: '1px solid #cfcfcf',
                            borderTop: 0,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                          }}
                        >
                          <div
                            style={{
                              background: '#001d3f',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                              padding: '4px',
                              fontSize: '16px',
                            }}
                          >
                            Payer data / Данные плательщика
                          </div>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  width: '30%',
                                  color: '#696969',
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#f2f2f2',
                                  padding: '4px',
                                  borderRight: '1px solid #cfcfcf',
                                  borderBottom: '1px solid #cfcfcf',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    lineHeight: '2em',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {'Payer\nПлательщик'}
                                </Typography.Text>
                              </div>
                              <div
                                style={{
                                  width: '70%',
                                  padding: '4px',
                                  lineHeight: '1em',
                                  color: '#696969',
                                  borderBottom: '1px solid #cfcfcf',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                <Typography.Text>
                                  {data.offerLender.orgName}
                                </Typography.Text>
                                <br />
                                {`${data.offerLender.rekvizits}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Документация */}
                        <div
                          style={{
                            border: '1px solid #cfcfcf',
                            borderTop: 0,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                          }}
                        >
                          <div
                            style={{
                              background: '#001d3f',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                              padding: '4px',
                              fontSize: '16px',
                            }}
                          >
                            Documentation / Документация
                          </div>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  width: '30%',
                                  padding: '4px',
                                  color: '#696969',
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#f2f2f2',
                                  borderRight: '1px solid #cfcfcf',
                                  borderBottom: '1px solid #cfcfcf',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    lineHeight: '2em',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {'Ref №\nПрилагаемые документы'}
                                </Typography.Text>
                              </div>
                              <div
                                style={{
                                  width: '70%',
                                  padding: '4px',
                                  color: '#696969',
                                  borderBottom: '1px solid #cfcfcf',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {`To the contract №${
                                  data.offerNumber
                                } from ${moment(data.createdAt).format(
                                  'DD.MM.YYYY',
                                )}\nК договору  №${
                                  data.offerNumber
                                } от ${moment(data.createdAt).format(
                                  'DD.MM.YYYY',
                                )}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Таблица */}
                        <div
                          style={{
                            border: '1px solid #cfcfcf',
                            borderTop: 0,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                          }}
                        >
                          <div style={{ color: '#001d3f' }}>
                            <table
                              style={{ border: 'collapse', width: '100%' }}
                            >
                              <thead>
                                <tr style={{ background: '#001d3f' }}>
                                  <td
                                    align="center"
                                    style={{ color: '#fff', padding: '6px' }}
                                  >
                                    №
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Date of loading\nДата загрузки'}
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Route\nМаршрут'}
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Truck/Trailer\nРег. номер автомобиля'}
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'SUMM\nСумма'}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    1
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {moment(data.dateLoad).format('DD.MM.YYYY')}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {data.way}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {data.carNumber}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {Number(data.priceFirm).toLocaleString(
                                      'ru-Ru',
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      },
                                    )}
                                    (
                                    {checkCurrency(
                                      data.currencyFirm,
                                    ).toLocaleUpperCase()}
                                    )
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={4}
                                    style={{
                                      padding: '4px',
                                      borderTop: '1px solid #d0d0d0',
                                    }}
                                  />
                                  <td
                                    style={{
                                      color: '#001d3f',
                                      padding: '4px',
                                      borderTop: '1px solid #d0d0d0',
                                    }}
                                  >
                                    <div>
                                      <div style={{ display: 'flex' }}>
                                        <div
                                          style={{
                                            whiteSpace: 'pre-line',
                                            marginRight: '20px',
                                          }}
                                        >
                                          {'Amount\nИтого'}
                                        </div>
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                          {`${Number(
                                            data.priceFirm,
                                          ).toLocaleString('ru-Ru', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}\n${Number(
                                            data.priceFirm,
                                          ).toLocaleString('ru-Ru', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}`}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          style={{
                            marginBottom: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography.Text>
                            Total : {data.priceFirm} {data.currencyFirm} (
                            {data.invoice?.textPriceLenderEn})
                          </Typography.Text>
                          <Typography.Text>
                            К оплате : {data.priceFirm} {data.currencyFirm} (
                            {data.invoice?.textPriceLenderRu})
                          </Typography.Text>
                        </div>
                        <div style={{ display: 'flex', position: 'relative' }}>
                          <div style={{ width: '50%' }}>
                            <Typography.Text style={{ fontWeight: 600 }}>
                              Директор
                            </Typography.Text>
                          </div>
                          <div style={{ width: '50%' }}>
                            <Typography
                              style={{ fontWeight: 600, fontSize: '1.1em' }}
                            >
                              {data.company === Firms.RCI_PMR
                                ? 'Смирнова О.В.'
                                : 'Ioseliani A.M.'}
                            </Typography>
                            <img
                              src={`${
                                process.env.PUBLIC_URL
                              }/img/stamp_${checkFirm(data.company)}.png`}
                              alt="Печать фирмы"
                              style={{
                                position: 'absolute',
                                bottom: '-70px',
                                right: '50px',
                                transform: 'rotate(-15deg)',
                                width: '60mm',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {/* Плательщик */}
                        <div
                          style={{
                            border: '1px solid #cfcfcf',
                            borderTop: 0,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                          }}
                        >
                          <div
                            style={{
                              background: '#001d3f',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                              padding: '4px',
                              fontSize: '16px',
                            }}
                          >
                            Payer data / Данные плательщика
                          </div>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  width: '30%',
                                  color: '#696969',
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#f2f2f2',
                                  padding: '4px',
                                  borderRight: '1px solid #cfcfcf',
                                  borderBottom: '1px solid #cfcfcf',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    lineHeight: '2em',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {'Payer\nПлательщик'}
                                </Typography.Text>
                              </div>
                              <div
                                style={{
                                  width: '70%',
                                  padding: '4px',
                                  lineHeight: '1em',
                                  color: '#696969',
                                  borderBottom: '1px solid #cfcfcf',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    fontWeight: 600,
                                    color: 'rgb(105, 105, 105)',
                                  }}
                                >
                                  {t(`${checkFirm(data.company)}.label`)}
                                </Typography.Text>
                                <br />
                                {t(`${checkFirm(data.company)}.rekv`)}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Документация */}
                        <div
                          style={{
                            border: '1px solid #cfcfcf',
                            borderTop: 0,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                          }}
                        >
                          <div
                            style={{
                              background: '#001d3f',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                              padding: '4px',
                              fontSize: '16px',
                            }}
                          >
                            Documentation / Документация
                          </div>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  width: '30%',
                                  padding: '4px',
                                  color: '#696969',
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#f2f2f2',
                                  borderRight: '1px solid #cfcfcf',
                                  borderBottom: '1px solid #cfcfcf',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    lineHeight: '2em',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {'Ref №\nПрилагаемые документы'}
                                </Typography.Text>
                              </div>
                              <div
                                style={{
                                  width: '70%',
                                  padding: '4px',
                                  color: '#696969',
                                  borderBottom: '1px solid #cfcfcf',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {`To the contract №${
                                  data.offerNumber
                                } from ${moment(data.createdAt).format(
                                  'DD.MM.YYYY',
                                )}\nК договору  №${
                                  data.offerNumber
                                } от ${moment(data.createdAt).format(
                                  'DD.MM.YYYY',
                                )}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Таблица */}
                        <div
                          style={{
                            border: '1px solid #cfcfcf',
                            borderTop: 0,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                          }}
                        >
                          <div style={{ color: '#001d3f' }}>
                            <table
                              style={{ border: 'collapse', width: '100%' }}
                            >
                              <thead>
                                <tr style={{ background: '#001d3f' }}>
                                  <td
                                    align="center"
                                    style={{ color: '#fff', padding: '6px' }}
                                  >
                                    №
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Date of loading\nДата загрузки'}
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Route\nМаршрут'}
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Truck/Trailer\nРег. номер автомобиля'}
                                  </td>
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'SUMM\nСумма'}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    1
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {moment(data.dateLoad).format('DD.MM.YYYY')}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {data.way}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {data.carNumber}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {Number(data.priceDriver).toLocaleString(
                                      'ru-Ru',
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      },
                                    )}
                                    (
                                    {checkCurrency(
                                      data.currencyDriver,
                                    ).toLocaleUpperCase()}
                                    )
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={4}
                                    style={{
                                      padding: '4px',
                                      borderTop: '1px solid #d0d0d0',
                                    }}
                                  />
                                  <td
                                    style={{
                                      color: '#001d3f',
                                      padding: '4px',
                                      borderTop: '1px solid #d0d0d0',
                                    }}
                                  >
                                    <div>
                                      <div style={{ display: 'flex' }}>
                                        <div
                                          style={{
                                            whiteSpace: 'pre-line',
                                            marginRight: '20px',
                                          }}
                                        >
                                          {'Amount\nИтого'}
                                        </div>
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                          {`${Number(
                                            data.priceDriver,
                                          ).toLocaleString('ru-Ru', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}\n${Number(
                                            data.priceDriver,
                                          ).toLocaleString('ru-Ru', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}`}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography.Text>
                            Total : {data.priceDriver} {data.currencyDriver} (
                            {data.invoice?.textPriceDriverEn})
                          </Typography.Text>
                          <Typography.Text>
                            К оплате : {data.priceDriver} {data.currencyDriver}{' '}
                            ({data.invoice?.textPriceDriverRu})
                          </Typography.Text>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography.Text style={{ fontWeight: 600 }}>
                            {' '}
                            Директор __________________
                          </Typography.Text>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </>
      </div>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
