import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetLocale,
  useNavigation,
  useSetLocale,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { UserContext } from 'UserProvider';
import { ClientType } from 'enums/client.type';
import { Firms } from 'enums/firms.type';
import { Languages } from 'enums/languages.type';
import { Role } from 'enums/role';
import { ICompanies, IOffer } from 'interfaces';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const checkFirm = (firm: Firms) => {
  switch (firm) {
    case Firms.RCI:
      return 'rci';
    case Firms.REAL:
      return 'real';
    case Firms.RCI_INVEST:
      return 'invest';
    case Firms.RCI_PMR:
      return 'rci_pmr';
    default:
      return 'real';
  }
};

export const OfferShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const ids = id?.split('&')[0];

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [visibleStamp, setVisibleStamp] = useState<boolean>(
    me?.role === Role.ACCOUNTANT ? true : false,
  );
  const type: ClientType =
    ClientType.FIRM === id?.split('&')[1]
      ? ClientType.FIRM
      : ClientType.TRANSPORT;

  const [offerType, setOfferType] = useState<ClientType>(type);
  const [hideBtn, setHideBtn] = useState<boolean>(true);

  const {
    queryResult: { data: response, isLoading, refetch: refetchOffer },
  } = useShow<IOffer>({ resource: 'offers', id: ids });

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (response?.data) {
  //     if (response?.data.language === Languages.RU) {
  //       console.log('ru', response?.data.language);
  //       changeLanguage('ru');
  //       // window.location.reload();
  //     } else {
  //       console.log('ro', response?.data.language);
  //       changeLanguage('ro');
  //       // window.location.reload();
  //     }
  //   }
  // }, [response]);
  if (!response) {
    return <Spin>Загрузка</Spin>;
  }
  const { data } = response;

  const leftSide = [
    {
      title: 'blank.typeData',
      data: [
        { title: 'blank.type', text: data.transportType },
        { title: 'blank.numberCar', text: data.carNumber },
        {
          title: 'blank.driverData',
          text:
            data.driverContact && data.driverContact !== ''
              ? JSON.parse(data.driverContact).map(
                  (contact: { fio: string; phone: string }) => (
                    <span>
                      {contact.fio} {contact.phone}
                    </span>
                  ),
                )
              : '',
        },
        {
          title: 'blank.docs',
          text:
            data.documents && data.documents !== ''
              ? JSON.parse(data.documents).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.documents).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
      ],
    },
    {
      title: 'blank.cargoInfo',
      data: [
        { title: 'blank.mass', text: `${data.mass} ${data.addMass}` },
        { title: 'blank.volum', text: `${data.volum} ${data.addVolum}` },
        { title: 'blank.typeCargo', text: data.characterCargo },
        { title: 'blank.temperature', text: data.thermoMode },
      ],
    },
    {
      title: 'blank.condition',
      data: [
        {
          title: 'blank.srecificalCondition',
          text:
            data.conditions && data.conditions !== ''
              ? JSON.parse(data.conditions).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.conditions).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
        {
          title: 'blank.transportCondition',
          text: t('blank.transportCondition_text', {
            lng: data.language === Languages.RU ? 'ru' : 'ro',
          }),
        },
        {
          title: 'blank.price',
          text: `${Number(
            offerType === ClientType.FIRM ? data.priceFirm : data.priceDriver,
          ).toLocaleString('ru-RU')}  (${
            offerType === ClientType.FIRM
              ? data.textPriceLender
              : data.textPriceDriver
          }) ${
            offerType === ClientType.FIRM
              ? data.currencyFirm
              : data.currencyDriver
          }`,
        },
        {
          title: 'blank.term',
          text: `${
            offerType === ClientType.FIRM
              ? t('blank.perecislenie.firm', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
              : t('blank.perecislenie.transport', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
          } ${
            offerType === ClientType.FIRM
              ? `${data.firmFrom}-${data.firmTo}`
              : `${data.driverFrom}-${data.driverTo}`
          } ${
            offerType === ClientType.FIRM
              ? t('blank.perecislenie2.firm', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
              : t('blank.perecislenie2.transport', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
          }`,
        },
        {
          title: 'blank.contactRCI',
          text: `${data.user.lastName} ${data.user.firstName} ${data.user.phone}`,
        },
      ],
    },
  ];

  const cancelEditPage = () => {
    document.designMode = 'off';
    setHideBtn(true);
  };
  const editPage = () => {
    document.designMode = 'on';
    setHideBtn(false);
  };

  function save(divName: any) {
    const printContents = document.getElementById(divName)?.innerHTML;
    const originalContents = document.body.innerHTML;
    const popupWin = window.open('', '_blank');
    if (divName && printContents && popupWin) {
      popupWin.document.open();
      popupWin.document.write(
        `<html><head ><title>${offerType} №${data.offerNumber}</title><style type="text/css">*{box-sizing:border-box;line-height:1rem;font-size:16px; -webkit-print-color-adjust: exact;} td{font-size: 10px} </style></head><body style="box-sizing: border-box;font-family: sans-serif;margin: 0;padding: 0;position: relative">` +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
      popupWin.print();
      // popupWin.onafterprint = function () {
      //   popupWin.close();
      // };
    }
  }

  return !isLoading && data ? (
    <CanAccess resource="offers" action="show">
      <div
        style={{
          overflow: 'auto',
          paddingBottom: '70px',
        }}
      >
        <>
          <Row gutter={[20, 20]}>
            <Col>
              <Space size={12}>
                <Button type="primary" onClick={() => save('printable')}>
                  Скачать заявку
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    offerType === ClientType.FIRM
                      ? setOfferType(ClientType.TRANSPORT)
                      : setOfferType(ClientType.FIRM)
                  }
                >
                  Заявка для{' '}
                  {offerType === ClientType.FIRM
                    ? ClientType.TRANSPORT
                    : ClientType.FIRM}
                </Button>
                {hideBtn ? (
                  <Tooltip title={t('can_edit_only_this_page')}>
                    <Button type="primary" onClick={editPage}>
                      Править
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('cancel_edit_mode')}>
                    <Button type="primary" danger onClick={cancelEditPage}>
                      Выключить режим правок
                    </Button>
                  </Tooltip>
                )}
                <CanAccess resource="offers" action="edit">
                  <Button
                    type="primary"
                    //   style={{ color: '#fff', '&:hover': { color: '#fff' } }}
                  >
                    Изменить
                  </Button>
                </CanAccess>
                <CanAccess resource="offers" action="stamp">
                  {!visibleStamp ? (
                    <Tooltip title={t('can_stamp_only_this_page')}>
                      <Button
                        type="primary"
                        onClick={() => setVisibleStamp(true)}
                      >
                        Показать печать
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('cancel_stamp_only_this_page')}>
                      <Button
                        type="primary"
                        danger
                        onClick={() => setVisibleStamp(false)}
                      >
                        Скрыть печать
                      </Button>
                    </Tooltip>
                  )}
                </CanAccess>
              </Space>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Progress size={180} />
                </div>
              ) : (
                <div className="printable" id="printable">
                  <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '28.9cm',
                      maxHeight: '28.9cm',
                      backgroundColor: '#ffffff',
                      padding: '0.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: '100%',
                        gap: '12px',
                      }}
                    >
                      <div style={{ marginBottom: '16px' }}>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/img/ico_logo.${checkFirm(data.company)}.png`}
                          alt="Real Cargo Logo"
                          style={{ width: '300px' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '16px',
                        }}
                      >
                        <Typography.Text
                          style={{ fontSize: '20px', fontWeight: 600 }}
                        >
                          {t('blank.mainLabel', {
                            lng: data.language === Languages.RU ? 'ru' : 'ro',
                          })}{' '}
                          №{data?.offerNumber}
                        </Typography.Text>
                      </div>

                      <div>
                        <div style={{ display: 'flex', gap: '12px' }}>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                borderBottom: '1px solid #e6e6e6',
                                flexWrap: 'nowrap',
                                display: 'flex',
                                marginBottom: 12,
                              }}
                            >
                              <Typography style={{ color: '#848484' }}>
                                {t('blank.for', {
                                  lng:
                                    data.language === Languages.RU
                                      ? 'ru'
                                      : 'ro',
                                })}
                              </Typography>
                              <Typography
                                style={{ color: '#101010', margin: '0 auto' }}
                              >
                                {offerType === ClientType.FIRM
                                  ? data.offerLender.orgName
                                  : data.offerDriver.orgName}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {leftSide.map(
                                (el: {
                                  title: string;
                                  data: { title: string; text: string }[];
                                }) => {
                                  return (
                                    <div>
                                      <div
                                        style={{
                                          border: '1px solid #cfcfcf',
                                          borderTop: 0,
                                          borderRadius: '5px',
                                          overflow: 'hidden',
                                          marginBottom: '12px',
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: '#001d3f',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#fff',
                                            padding: '5px',
                                          }}
                                        >
                                          {t(el.title, {
                                            lng:
                                              data.language === Languages.RU
                                                ? 'ru'
                                                : 'ro',
                                          })}
                                        </div>
                                        <div>
                                          {el.data.map((row, index) => {
                                            const params =
                                              row?.title?.length >= 30
                                                ? {
                                                    lineHeight: '0.9em',
                                                    fontSize: '0.8em',
                                                  }
                                                : {
                                                    lineHeight: '1em',
                                                    fontSize: '0.9em',
                                                  };

                                            if (
                                              offerType !==
                                                ClientType.TRANSPORT &&
                                              row.title ===
                                                'blank.transportCondition'
                                            )
                                              return;
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  display: 'flex',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    color: '#696969',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '40%',
                                                    background: '#f2f2f2',
                                                    borderRight:
                                                      '1px solid #cfcfcf',
                                                    borderBottom:
                                                      '1px solid #cfcfcf',
                                                    padding: '2px',
                                                  }}
                                                >
                                                  <Typography.Text
                                                    style={{
                                                      lineHeight: '1em',
                                                    }}
                                                  >
                                                    {t(row.title, {
                                                      lng:
                                                        data.language ===
                                                        Languages.RU
                                                          ? 'ru'
                                                          : 'ro',
                                                    })}
                                                  </Typography.Text>
                                                </div>
                                                <div
                                                  style={{
                                                    color: '#696969',
                                                    ...params,
                                                    width: '60%',

                                                    borderBottom:
                                                      '1px solid #cfcfcf',
                                                    padding: '2px',
                                                  }}
                                                >
                                                  {row.text}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                            {/* {data.left.map((item, id) => (
                                <div key={id}>
                                  <BlankInfoCard item={item} />
                                </div>
                              ))} */}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                borderBottom: '1px solid #e6e6e6',
                                flexWrap: 'nowrap',
                                display: 'flex',
                                marginBottom: 12,
                              }}
                            >
                              <Typography style={{ color: '#848484' }}>
                                {t('blank.from', {
                                  lng:
                                    data.language === Languages.RU
                                      ? 'ru'
                                      : 'ro',
                                })}
                              </Typography>
                              <Typography
                                style={{ color: '#101010', margin: '0 auto' }}
                              >
                                {moment(data.createdAt).format('DD.MM.YYYY')}
                              </Typography>
                            </div>

                            <div
                              style={{
                                border: '3px solid #001d3f',
                                borderTop: '1px',
                                borderRadius: '5px',
                                overflow: 'hidden',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#001d3f',
                                  color: '#fff',
                                  padding: '10px',
                                  textAlign: 'center',
                                }}
                              >
                                {t('blank.direction', {
                                  lng:
                                    data.language === Languages.RU
                                      ? 'ru'
                                      : 'ro',
                                })}
                              </div>
                              <div>
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      color: '#696969',
                                      borderRight: '1px solid #cfcfcf',
                                      background: '#f2f2f2',
                                      width: '40%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      borderBottom: '1px solid #cfcfcf',
                                      padding: '2px',
                                    }}
                                  >
                                    <Typography.Text>
                                      {t('blank.way', {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                    </Typography.Text>
                                  </div>
                                  <div
                                    style={{
                                      color: '#696969',
                                      borderBottom: '1px solid #cfcfcf',
                                      whiteSpace: 'pre-line',
                                      padding: '2px',
                                      width: '60%',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {data.way}
                                  </div>
                                </div>
                                <Row
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 2,
                                    background: '#e0e0e0',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography.Text style={{ color: '#001d3f' }}>
                                    {t('blank.loading', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                                  </Typography.Text>
                                </Row>
                                {[
                                  {
                                    title: 'blank.shipmentDate',
                                    value: `${
                                      data.dateLoadAdd &&
                                      data.dateLoadAdd !== ''
                                        ? data.dateLoadAdd + ' - '
                                        : ''
                                    }${moment(data.dateLoad).format(
                                      'DD.MM.YYYY',
                                    )}`,
                                  },
                                  {
                                    title: 'blank.shipmentTime',
                                    value: moment(data.dateLoad).format(
                                      'HH:mm',
                                    ),
                                  },
                                  {
                                    title: 'blank.shipmentAdress',
                                    value: (
                                      <Space direction="vertical" size={2}>
                                        {JSON.parse(data.adressLoad).map(
                                          (adresses: { adress: string }) => (
                                            <>
                                              {`${adresses.adress}`}
                                              {}
                                            </>
                                          ),
                                        )}
                                      </Space>
                                    ),
                                  },
                                  {
                                    title: 'blank.shipmentContact',
                                    value: JSON.parse(data.contactLoad).map(
                                      (contact: {
                                        fio: string;
                                        phone: string;
                                      }) => (
                                        <>
                                          {contact.fio} {contact.phone}
                                        </>
                                      ),
                                    ),
                                  },
                                ].map((el) => (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        color: '#696969',
                                        borderRight: '1px solid #cfcfcf',
                                        background: '#f2f2f2',
                                        width: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cfcfcf',
                                        padding: '2px',
                                      }}
                                    >
                                      <Typography.Text>
                                        {t(el.title, {
                                          lng:
                                            data.language === Languages.RU
                                              ? 'ru'
                                              : 'ro',
                                        })}
                                      </Typography.Text>
                                    </div>
                                    <div
                                      style={{
                                        color: '#696969',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '60%',

                                        borderBottom: '1px solid #cfcfcf',
                                        whiteSpace: 'pre-line',
                                        padding: '2px',
                                      }}
                                    >
                                      {el.value}
                                    </div>
                                  </div>
                                ))}

                                <Row
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 2,
                                    backgroundColor: '#e0e0e0',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography.Text style={{ color: '#001d3f' }}>
                                    {t('blank.vama', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                                  </Typography.Text>
                                </Row>
                                {[
                                  {
                                    title: 'blank.vamaExport',
                                    value: data.vamaExport,
                                  },
                                  {
                                    title: 'blank.vamaBroker',
                                    value: data.broker,
                                  },
                                  {
                                    title: 'blank.vamaDirection',
                                    value: data.vamaPogran,
                                  },
                                  {
                                    title: 'blank.vamaImport',
                                    value: data.vamaImport,
                                  },
                                ].map((el) => (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        color: '#696969',
                                        borderRight: '1px solid #cfcfcf',
                                        backgroundColor: '#f2f2f2',
                                        width: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cfcfcf',
                                        padding: '2px',
                                      }}
                                    >
                                      <Typography.Text>
                                        {t(el.title, {
                                          lng:
                                            data.language === Languages.RU
                                              ? 'ru'
                                              : 'ro',
                                        })}
                                      </Typography.Text>
                                    </div>
                                    <div
                                      style={{
                                        color: '#696969',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '60%',

                                        borderBottom: '1px solid #cfcfcf',
                                        whiteSpace: 'pre-line',
                                        padding: '2px',
                                      }}
                                    >
                                      {el.value}
                                    </div>
                                  </div>
                                ))}
                                <Row
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 2,
                                    background: '#e0e0e0',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography.Text style={{ color: '#001d3f' }}>
                                    {t('blank.unloading', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                                  </Typography.Text>
                                </Row>
                                {[
                                  {
                                    title: 'blank.unloadingDate',
                                    value: `${
                                      data.dateUnLoadAdd &&
                                      data.dateUnLoadAdd !== ''
                                        ? data.dateUnLoadAdd + ' - '
                                        : ''
                                    }${moment(data.dateUnLoad).format(
                                      'DD.MM.YYYY',
                                    )}`,
                                  },
                                  {
                                    title: 'blank.unloadingAdress',
                                    value: data.adressUnLoading,
                                  },
                                ].map((el) => (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        color: '#696969',
                                        borderRight: '1px solid #cfcfcf',
                                        background: '#f2f2f2',
                                        width: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cfcfcf',
                                        padding: '2px',
                                      }}
                                    >
                                      <Typography.Text>
                                        {t(el.title, {
                                          lng:
                                            data.language === Languages.RU
                                              ? 'ru'
                                              : 'ro',
                                        })}
                                      </Typography.Text>
                                    </div>
                                    <div
                                      style={{
                                        color: '#696969',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '60%',

                                        borderBottom: '1px solid #cfcfcf',
                                        whiteSpace: 'pre-line',
                                        padding: '2px',
                                      }}
                                    >
                                      {el.value}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        <div style={{ width: '50%' }}>
                          <div
                            style={{
                              borderBottom: '1px solid #e6e6e6',
                              flexWrap: 'wrap',
                              display: 'flex',
                            }}
                          >
                            <Typography.Text
                              style={{
                                color: '#848484',
                                width: '100%',
                              }}
                            >
                              {offerType === ClientType.FIRM
                                ? t('blank.zakazcik', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })
                                : t('blank.transport', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                            </Typography.Text>
                            <Typography
                              style={{ color: '#101010', fontWeight: 'bold' }}
                            >
                              {offerType === ClientType.FIRM
                                ? data.offerLender.orgName
                                : data.offerDriver.orgName}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ width: '50%' }}>
                          <div
                            style={{
                              borderBottom: '1px solid #e6e6e6',
                              flexWrap: 'wrap',
                              display: 'flex',
                            }}
                          >
                            {visibleStamp && (
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/img/stamp_${checkFirm(data.company)}.png`}
                                alt="Печать фирмы"
                                style={{
                                  position: 'absolute',
                                  right: '60px',
                                  bottom: '40px',
                                  transform: 'rotate(-7deg)',
                                  width: '60mm',
                                }}
                              />
                            )}
                            <Typography
                              style={{ color: '#848484', width: '100%' }}
                            >
                              {offerType === ClientType.FIRM
                                ? t('blank.expeditor', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })
                                : t('blank.clientExpeditor', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                            </Typography>
                            <Typography.Text
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {t(`${checkFirm(data.company)}.label`, {
                                lng:
                                  data.language === Languages.RU ? 'ru' : 'ro',
                              })}
                            </Typography.Text>
                            {/* <Typography style={{ whiteSpace: 'pre-line' }}>
                                {translate(`${checkFirm(data.company)}.rekv`)}
                              </Typography> */}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        1/2
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '29.7cm',
                      maxHeight: '29.7cm',
                      backgroundColor: '#ffffff',
                      padding: '1.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: 'auto',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: '100%',
                        gap: '12px',
                      }}
                    >
                      <div>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/img/ico_logo.${checkFirm(data.company)}.png`}
                          alt="Real Cargo Logo"
                          style={{ marginTop: '40px', width: '300px' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '16px',
                        }}
                      >
                        <Typography.Text
                          style={{ fontSize: '20px', fontWeight: 600 }}
                        >
                          {t('blank.mainLabel', {
                            lng: data.language === Languages.RU ? 'ru' : 'ro',
                          })}{' '}
                          №{data?.offerNumber}
                        </Typography.Text>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '16px 0',
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              gap: '12px',
                              margin: '16px 0',
                            }}
                          >
                            <div style={{ width: '50%' }}>
                              <div
                                style={{
                                  borderBottom: '1px solid #e6e6e6',
                                  flexWrap: 'nowrap',
                                  display: 'flex',
                                }}
                              >
                                <Typography style={{ color: '#848484' }}>
                                  {t('blank.for', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography>
                                <Typography
                                  style={{
                                    color: '#101010',
                                    margin: '0 auto',
                                  }}
                                >
                                  {offerType === ClientType.FIRM
                                    ? data.offerLender.orgName
                                    : data.offerDriver.orgName}
                                </Typography>
                              </div>
                            </div>
                            <div style={{ width: '50%' }}>
                              <Col
                                style={{
                                  borderBottom: '1px solid #e6e6e6',
                                  flexWrap: 'nowrap',
                                  display: 'flex',
                                }}
                              >
                                <Typography style={{ color: '#848484' }}>
                                  {t('blank.from', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography>

                                <Typography
                                  style={{
                                    color: '#101010',
                                    margin: '0 auto',
                                  }}
                                >
                                  {moment(data.createdAt).format('DD.MM.YYYY')}
                                </Typography>
                              </Col>
                            </div>
                          </div>
                          <div style={{ width: '100%', margin: '0 0 12px 0' }}>
                            <div
                              style={{
                                border: '1px solid #cfcfcf',
                                overflow: 'hidden',
                                borderRadius: '8px',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#001d3f',
                                  color: '#fff',

                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography.Text
                                  style={{ color: '#fff', fontSize: '1rem' }}
                                >
                                  {t('blank.conditions.label', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography.Text>
                              </div>
                              <div
                                style={{
                                  fontSize: '0.7em',
                                  lineHeight: '1em',
                                  padding: '10px',
                                  textAlign: 'justify',
                                }}
                              >
                                {offerType === ClientType.FIRM
                                  ? t('blank.conditions.text.firm', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })
                                  : t('blank.conditions.text.transport', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                              </div>
                              <div style={{ padding: '10px' }}>
                                <Typography color="#001d3f">
                                  {t('blank.conditions.subtitle', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', gap: '12px' }}>
                              <div style={{ width: '50%' }}>
                                <div
                                  style={{
                                    border: '1px solid #e0e0e0',
                                    overflow: 'hidden',
                                    borderRadius: '8px',
                                    height: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      background: '#e0e0e0',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: 1,
                                      color: '#001d3f',
                                    }}
                                  >
                                    <Typography>
                                      {offerType === ClientType.FIRM
                                        ? t('blank.zakazcik', {
                                            lng:
                                              data.language === Languages.RU
                                                ? 'ru'
                                                : 'ro',
                                          })
                                        : t('blank.transport', {
                                            lng:
                                              data.language === Languages.RU
                                                ? 'ru'
                                                : 'ro',
                                          })}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      padding: 10,
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography.Text
                                      style={{
                                        fontWeight: 'bold',
                                        whiteSpace: 'pre-line',
                                        display: 'inline-block',
                                        lineHeight: '1.3em',
                                      }}
                                    >
                                      {offerType === ClientType.FIRM
                                        ? data.offerLender.orgName
                                        : data.offerDriver.orgName}
                                    </Typography.Text>
                                    <Typography
                                      style={{
                                        whiteSpace: 'pre-line',
                                        display: 'inline-block',
                                        lineHeight: '1.3em',
                                      }}
                                    >
                                      {offerType === ClientType.FIRM
                                        ? data.offerLender.rekvizits
                                        : data.offerDriver.rekvizits}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: '50%' }}>
                                <div
                                  style={{
                                    border: '1px solid #e0e0e0',
                                    // overflow: 'hidden',
                                    borderRadius: '8px',
                                    height: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      background: '#e0e0e0',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      color: '#001d3f',
                                      padding: 1,
                                    }}
                                  >
                                    <Typography>
                                      {offerType === ClientType.FIRM
                                        ? t('blank.expeditor', {
                                            lng:
                                              data.language === Languages.RU
                                                ? 'ru'
                                                : 'ro',
                                          })
                                        : t('blank.clientExpeditor', {
                                            lng:
                                              data.language === Languages.RU
                                                ? 'ru'
                                                : 'ro',
                                          })}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      padding: 10,
                                      position: 'relative',
                                    }}
                                  >
                                    {visibleStamp && (
                                      <img
                                        src={`${
                                          process.env.PUBLIC_URL
                                        }/img/stamp_${checkFirm(
                                          data.company,
                                        )}.png`}
                                        alt="Печать фирмы"
                                        style={{
                                          position: 'absolute',
                                          right: '0px',
                                          bottom: '-30px',
                                          transform: 'rotate(-15deg)',
                                          width: '60mm',
                                        }}
                                      />
                                    )}
                                    <Typography.Text
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {t(`${checkFirm(data.company)}.label`, {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                    </Typography.Text>
                                    <Typography
                                      style={{ whiteSpace: 'pre-line' }}
                                    >
                                      {t(`${checkFirm(data.company)}.rekv`, {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      2/2
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </>
      </div>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
