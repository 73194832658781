import { useUpdate } from '@pankod/refine-core';
import { DatePicker, Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { PaymentStatus } from 'enums/payment.status';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ModalSetData({
  open,
  close,
  selectedUuid,
  refetch,
}: {
  open: boolean;
  close: any;
  selectedUuid: number;
  refetch: any;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { mutate: update } = useUpdate();
  const save = () => {
    const data = form.getFieldValue('datePayment');
    const datePayment = dayjs(data).format('YYYY-MM-DD');
    update(
      {
        resource: 'payment',
        id: selectedUuid,
        values: { datePayment: datePayment, status: PaymentStatus.SET_DATA },
        successNotification: {
          description: t('update.titleSuccess'),
          message: t('update.textSuccess'),
          type: 'success',
        },
        errorNotification: {
          description: t('error.createText'),
          message: 'Возникла ошибка',
          type: 'error',
        },
      },
      {
        onSuccess: () => {
          refetch();
          close();
        },
      },
    );
  };

  return (
    <Modal
      title={t('payments.modal.title')}
      open={open}
      onCancel={close}
      onOk={save}
    >
      <Form form={form}>
        <Form.Item name="datePayment" label="Укажите дату">
          <DatePicker style={{ width: '100%' }} format={'DD.MM.YYYY'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
