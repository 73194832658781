import {
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core';
import {
  Button,
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  Rate,
  Select,
  ShowButton,
  Space,
  Switch,
  Table,
  TagField,
  TextField,
  Tooltip,
  useTable,
  Form,
  Col,
  Row,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';
import dayjs from 'dayjs';
import { Firms } from 'enums/firms.type';
import { Country } from 'enums/country';

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  const { mutate } = useUpdate({});
  // const { data: me } = useGetIdentity<IUser>();
  const initialFilter: CrudFilters = [
    { field: 'enabled', value: [1], operator: 'in' },
  ];

  const { tableProps, sorter, setFilters } = useTable<IUser>({
    initialSorter: [
      {
        field: 'stars',
        order: 'desc',
      },
    ],
    initialFilter,
  });

  const [form] = Form.useForm();

  const changeFilters = (values: any) => {
    const data = form.getFieldsValue();
    console.log(data);
    setFilters([
      {
        field: 'enabled',
        operator: 'in',
        value: data.active ? [1] : [0, 1],
      },
    ]);
  };

  return (
    <List
      headerProps={{
        extra: (
          <CreateButton
            style={{ marginTop: 10 }}
            type={'primary'}
            children="Создать пользователя"
          />
        ),
      }}
    >
      {open && (
        <ModalAgentList
          uuid={selectedUuid}
          open={open}
          cancel={() => setOpen(false)}
        />
      )}
      <Form
        form={form}
        initialValues={{
          createdAt: dayjs(),
          company: '',
          lender: '',
          offerNumber: '',
          finished: false,
          in_work: false,
        }}
        layout="vertical"
      >
        <Row gutter={[6, 2]}>
          <Col xs={12} md={3}>
            <Form.Item name="active">
              <Switch
                defaultChecked
                checkedChildren="Активные"
                unCheckedChildren="Все"
                onChange={changeFilters}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table {...tableProps} rowKey="uuid">
        <Table.Column
          dataIndex="enabled"
          key="enabled"
          title="Активен"
          width={40}
          render={(value, record: IUser) => (
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={!!value}
              onChange={() =>
                mutate({
                  resource: 'users',
                  id: record.uuid,
                  values: { enabled: !value },
                })
              }
            />
          )}
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="Email"
          render={(value) => (
            <EmailField style={{ whiteSpace: 'nowrap' }} value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder('email', sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="stars"
          key="stars"
          title="Рейтинг"
          render={(value) => (
            <Rate
              disabled
              value={Number(value)}
              allowHalf
              tooltips={[
                `Рейтинг ${value}`,
                `Рейтинг ${value}`,
                `Рейтинг ${value}`,
                `Рейтинг ${value}`,
                `Рейтинг ${value}`,
              ]}
            />
          )}
          defaultSortOrder={getDefaultSortOrder('stars', sorter)}
          sorter
        />
        <Table.Column
          dataIndex="lastName"
          key="lastName"
          title="Фамилия"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('lastName', sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="firstName"
          key="firstName"
          title="Имя"
          render={(value) => (
            <TextField style={{ wordBreak: 'normal' }} value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder('firstName', sorter)}
          sorter
        />

        <Table.Column
          dataIndex="role"
          key="role"
          title="Роль"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder('role', sorter)}
          sorter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Выберите роли"
                options={Object.values(Role).map((status) => ({
                  label: status,
                  value: status,
                }))}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Создан"
          render={(value) => (
            <DateField value={value} format={'DD.MM.YYYY, HH:mm'} />
          )}
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          sorter
        />
        <Table.Column
          key="action"
          title="Функции"
          render={(_, value: any) => (
            <Space size={4} wrap>
              <EditButton recordItemId={value.uuid} hideText size="small" />
              {/* <DeleteButton recordItemId={value.uuid} hideText size="small" /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
