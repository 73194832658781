import { Avatar, Spin, TextField } from '@pankod/refine-antd';
import React from 'react';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { IPicture, IUser } from 'interfaces';
import { DocumentType } from 'enums/document.type';
import { useGetIdentity } from '@pankod/refine-core';
import { useNavigate } from '@pankod/refine-react-router-v6';

export default function Profile(props: {
  isMobile: boolean;
  colapsed: boolean;
  me: IUser;
}) {
  const navigate = useNavigate();
  let avatar;
  if (props.me) {
    avatar = props.me.pictures;
  }

  return props.me ? (
    <div
      style={{
        padding: 20,
        display: 'flex',
      }}
    >
      {!avatar && (
        <Avatar
          shape="square"
          size={props.colapsed ? 40 : 100}
          icon={<UserOutlined />}
        />
      )}
      {avatar && (
        <Avatar
          shape="square"
          size={props.colapsed ? 40 : 100}
          icon={<UserOutlined />}
          src={process.env.REACT_APP_BACKEND_URL + avatar.path}
        />
      )}
      {!props.colapsed && (
        <div
          style={{
            marginLeft: 8,
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(`/users/edit/${props.me.uuid}`);
          }}
        >
          <span>
            {props.me?.lastName} {props.me?.firstName}
          </span>
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        padding: 20,
        display: 'flex',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin />
      <span style={{ marginLeft: 8, color: '#fff' }}>Загрузка</span>
    </div>
  );
}
