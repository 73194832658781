import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  useForm,
  useSelect,
  theme,
} from '@pankod/refine-antd';
import { CanAccess } from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColorPickerProps } from 'antd';
import { AutoComplete, ColorPicker } from 'antd';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors';
import { CalendarType } from 'enums/calendar.type';

const { TextArea } = Input;

export default function ModalAddCalendar({
  open,
  onCancel,
  refetch,
  type,
  uuid,
}: {
  open: boolean;
  refetch: any;
  onCancel: any;
  type: CalendarType;
  uuid?: number;
}) {
  const { t } = useTranslation();
  const { formProps, saveButtonProps, queryResult, form } = useForm<ICompanies>(
    {
      resource: 'calendar',
      redirect: false,
      onMutationSuccess: () => {
        onCancel();
        if (uuid) {
          refetch();
        }
      },
      successNotification: {
        description: t('calendar.create'),
        message: t('calendar.createText'),
        type: 'success',
      },
      errorNotification: {
        description: 'Запись не создана',
        message: 'Возникла ошибка',
        type: 'error',
      },
    },
  );

  const { queryResult: selectResult } = useSelect({
    resource:
      type === CalendarType.LOGISTIC
        ? 'companies/calendar/offerFirms'
        : 'companies/calendar/offerDriver',
  });
  const { data } = selectResult;

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  type Presets = Required<ColorPickerProps>['presets'][number];

  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map<Presets>(([label, colors]) => ({
      label,
      colors,
    }));
  const { token } = theme.useToken();

  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });

  const customPanelRender: ColorPickerProps['panelRender'] = (
    _,
    { components: { Picker, Presets } },
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" style={{ height: 'auto' }} />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  return (
    <Modal
      okButtonProps={saveButtonProps}
      open={open}
      width={580}
      onCancel={onCancel}
      title={`${t('calendar.add')}`}
    >
      <Form
        {...formProps}
        form={form}
        requiredMark
        layout="horizontal"
        initialValues={{
          orgUuid: uuid ? uuid : null,
          dateCall: null,
          color: '#0092fd',
          type: type,
        }}
        size="small"
        scrollToFirstError
        labelCol={{ xs: 8 }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            label="Название компании"
            name="orgUuid"
            labelAlign="left"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={data?.data.map((option) => {
                return { value: option.uuid, label: option.orgName };
              })}
              showSearch
              allowClear
              optionFilterProp="children"
              virtual
              placeholder="Выберите из списка"
              filterOption={(inputValue, option) => {
                return (
                  option?.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                );
              }}
            />
          </Form.Item>

          <Form.Item
            label={t('calendar.dateCall')}
            name="dateCall"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              picker="date"
              size="small"
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={t('calendar.text')}
            name="text"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item
            label={t('calendar.type')}
            name="type"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled={type === CalendarType.ASSIG} size="small">
              <Select.Option value={CalendarType.LOGISTIC}>
                {CalendarType.LOGISTIC}
              </Select.Option>
              <Select.Option value={CalendarType.ASSIG}>
                {CalendarType.ASSIG}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('calendar.color')}
            name="color"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <ColorPicker
              defaultValue={token.colorPrimary}
              styles={{ popupOverlayInner: { width: 480 } }}
              arrow
              showText
              size="large"
              presets={presets}
              panelRender={customPanelRender}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
}
