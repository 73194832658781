import { Languages } from 'enums/languages.type';

const docs: { [key in Languages.RU | Languages.RO]: string[] } = {
  'Русский язык': [
    'CMR',
    'CMR/TIR',
    'CMR+TIR',
    'CMR-страхование',
    'Инвойс',
    'Упаковочный лист',
    'СТ1 или EU1',
    'ADR',
    'ADR class 1',
    'ADR class 2',
    'ADR class 3',
    'ADR class 4',
    'ADR class 5',
    'ADR class 6',
    'ADR class 7',
    'ADR class 8',
    'ADR class 9',
  ],
  'Limba Românǎ': [
    'CMR',
    'CMR/TIR',
    'CMR+TIR',
    'CMR-asigurare',
    'Invoice',
    'Lista de ambalare ( Packing list )',
    'СТ1 sau EU1',
    'ADR',
    'ADR class 1',
    'ADR class 2',
    'ADR class 3',
    'ADR class 4',
    'ADR class 5',
    'ADR class 6',
    'ADR class 7',
    'ADR class 8',
    'ADR class 9',
  ],
};

export default docs;
