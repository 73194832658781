import { GetListResponse } from '@pankod/refine-core';
import { IPayment } from 'interfaces';
import * as XLSX from 'xlsx';

export const saveList = (data: GetListResponse<IPayment>) => {
  const saveArray: any[] = [];
  const driversList = data.data;
  driversList.forEach((elm: IPayment) => {
    const { vamaDriver } = elm;
    const { cursDriver, offer } = vamaDriver;

    const { offerDriver, priceDriver, offerNumber: blank_number } = offer;

    const { orgName: transport } = offerDriver;
    const cash = +priceDriver * +cursDriver;
    //     const { blank_number, transport, cash } = elm;
    const newObject = Object.assign({}, { blank_number, transport, cash });
    const newObjectWithRussian: any = {};
    for (const [key, value] of Object.entries(newObject)) {
      if (key === 'blank_number') {
        newObjectWithRussian['Заявка'] = value;
      } else if (key === 'transport') {
        newObjectWithRussian['Транспорт'] = value;
      } else if (key === 'cash') {
        newObjectWithRussian['Сумма'] = value;
      } else {
        newObjectWithRussian[key] = value;
      }
    }
    saveArray.push(newObjectWithRussian);
  });

  const dataToPrint = {
    data: saveArray,
    columns: [
      { name: 'Заявка', width: 10 },
      { name: 'Транспорт', width: 40 },
      { name: 'Сумма', width: 10 },
    ],
  };

  const worksheet = XLSX.utils.json_to_sheet(dataToPrint.data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Транспорт');

  const wscols = dataToPrint.columns.map((col) => ({
    wch: col.width,
  }));

  worksheet['!cols'] = wscols;

  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;

  const fileName = 'Отчет по транспорту.xlsx';
  link.setAttribute('download', fileName);
  link.click();
  window.URL.revokeObjectURL(url);
};
