import { Role } from './enums/role';
import { OfferStatus } from './enums/offer.status';
import { UserStatus } from './enums/user.status';
import { InvestmentStatus } from './enums/investment.status';
import { AuthProvider, IAccessControlContext } from '@pankod/refine-core';
import { axiosInstance } from './App';
import { IRegistry } from './interfaces';
import { IUser } from './interfaces';
import { CanReturnType } from '@pankod/refine-core';
import { updateUser } from './userUpdater';

interface ResourceActions {
  [key: string]: {
    [key: string]: boolean;
  };
}
let cachedMe: IUser | null = null;
let isFetching = false;
const accessCache: { [key: string]: { can: boolean } } = {};

export const accessControlProvider: Required<IAccessControlContext> = {
  can: async ({ resource, action, params }): Promise<CanReturnType> => {
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    let me;

    if (cachedMe) {
      me = cachedMe;
    } else {
      if (isFetching) {
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (!isFetching) {
              clearInterval(intervalId);
              resolve(null);
            }
          }, 100);
        });
        me = cachedMe;
      } else {
        isFetching = true;
        try {
          const { data } = await axiosInstance.get(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/profile/me',
          );
          cachedMe = data;
          me = data;
        } catch (error) {
          throw error;
        } finally {
          isFetching = false;
        }
      }
    }

    // ТОЛЬКО менеджер страховщик
    if (me.role === Role.MANAGER_ASIG) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'history' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        (resource === 'users' && action === 'edit') ||
        resource === 'assig'
      ) {
        return Promise.resolve({ can: true });
      }
    }
    // ТОЛЬКО менеджер
    if (me.role === Role.MANAGER) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'airlines' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'showLogist' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_ships' &&
          (action === 'list' || action === 'all' || action === 'edit')) ||
        (resource === 'vama_expedition' &&
          (action === 'list' || action === 'all' || action === 'edit')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' && action === 'list') ||
        (resource === 'payment_driver' && action === 'list') ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        resource === 'assig' ||
        resource === 'ticket' ||
        resource === 'invoice' ||
        (resource === 'act' && (action === 'list' || action === 'show')) ||
        (resource === 'offers' &&
          (action === 'list' ||
            action === 'duplicate' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'show')) ||
        (resource === 'ships' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'create')) ||
        (resource === 'users' && action === 'edit') ||
        resource === 'trains'
      ) {
        return Promise.resolve({ can: true });
      }
    }
    // ТОЛЬКО менеджер
    if (me.role === Role.MANAGER_BIG) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'company' ||
        resource === 'airlines' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'showLogist' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_ships' &&
          (action === 'list' || action === 'all' || action === 'edit')) ||
        (resource === 'vama_expedition' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'showAll' ||
            action === 'edit')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' && action === 'list') ||
        (resource === 'payment_driver' && action === 'list') ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        (resource === 'offers' &&
          (action === 'list' ||
            action === 'duplicate' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'show')) ||
        (resource === 'ships' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'create')) ||
        resource === 'assig' ||
        resource === 'ticket' ||
        resource === 'invoice' ||
        (resource === 'act' && (action === 'list' || action === 'show')) ||
        resource === 'trains' ||
        (resource === 'users' && action === 'edit')
      ) {
        return Promise.resolve({ can: true });
      }
    }
    // ТОЛЬКО Директор
    if (me.role === Role.DIRECTOR) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'airlines' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'editOther' ||
            action === 'showLogist' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_ships' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'edit' ||
            action === 'confirm')) ||
        (resource === 'vama_expedition' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'showAll' ||
            action === 'edit' ||
            action === 'confirm')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' &&
          (action === 'list' || action === 'confirm')) ||
        (resource === 'payment_driver' &&
          (action === 'list' || action === 'confirm')) ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        (resource === 'offers' &&
          (action === 'list' ||
            action === 'duplicate' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'show' ||
            action === 'stamp' ||
            action === 'delete')) ||
        (resource === 'ships' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'fullInfo' ||
            action === 'canAll' ||
            action === 'create')) ||
        resource === 'assig' ||
        resource === 'ticket' ||
        resource === 'invoice' ||
        (resource === 'act' &&
          (action === 'list' || action === 'show' || action === 'edit')) ||
        resource === 'trains' ||
        resource === 'cash' ||
        (resource === 'users' &&
          (action === 'list' ||
            action === 'create' ||
            action === 'review' ||
            action === 'delete' ||
            action === 'edit' ||
            action === 'admin'))
      ) {
        return Promise.resolve({ can: true });
      }
    }
    // ТОЛЬКО админ
    if (me.role === Role.ADMIN) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'airlines' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'editOther' ||
            action === 'showLogist' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_ships' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'edit' ||
            action === 'confirm')) ||
        (resource === 'vama_expedition' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'showAll' ||
            action === 'edit' ||
            action === 'confirm')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' &&
          (action === 'list' || action === 'confirm')) ||
        (resource === 'payment_driver' &&
          (action === 'list' || action === 'confirm')) ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        (resource === 'offers' &&
          (action === 'list' ||
            action === 'duplicate' ||
            action === 'show' ||
            action === 'stamp' ||
            action === 'edit' ||
            action === 'create' ||
            action === 'delete')) ||
        (resource === 'ships' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'fullInfo' ||
            action === 'canAll' ||
            action === 'create')) ||
        resource === 'assig' ||
        resource === 'ticket' ||
        resource === 'invoice' ||
        (resource === 'act' &&
          (action === 'list' || action === 'show' || action === 'edit')) ||
        resource === 'trains' ||
        (resource === 'users' && (action === 'review' || action === 'edit'))
      ) {
        return Promise.resolve({ can: true });
      }
    }
    // ТОЛЬКО управляющий
    if (me.role === Role.CONTROL) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'airlines' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'editOther' ||
            action === 'showLogist' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_ships' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'edit' ||
            action === 'confirm')) ||
        (resource === 'vama_expedition' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'showAll' ||
            action === 'edit' ||
            action === 'confirm')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' &&
          (action === 'list' || action === 'confirm')) ||
        (resource === 'payment_driver' &&
          (action === 'list' || action === 'confirm')) ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        (resource === 'offers' &&
          (action === 'list' ||
            action === 'duplicate' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'create' ||
            action === 'delete')) ||
        (resource === 'ships' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'fullInfo' ||
            action === 'canAll' ||
            action === 'create')) ||
        resource === 'assig' ||
        resource === 'ticket' ||
        resource === 'invoice' ||
        (resource === 'act' &&
          (action === 'list' || action === 'show' || action === 'edit')) ||
        resource === 'trains' ||
        (resource === 'users' && (action === 'review' || action === 'edit'))
      ) {
        return Promise.resolve({ can: true });
      }
    }
    // ТОЛЬКО Я
    if (me.role === Role.SYSTEM) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'airlines' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'editOther' ||
            action === 'showLogist' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_ships' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'edit' ||
            action === 'confirm')) ||
        (resource === 'vama_expedition' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'showAll' ||
            action === 'edit' ||
            action === 'confirm')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' &&
          (action === 'list' || action === 'confirm')) ||
        (resource === 'payment_driver' &&
          (action === 'list' || action === 'confirm')) ||
        resource === 'calendars' ||
        resource === 'calendar' ||
        (resource === 'offers' &&
          (action === 'list' ||
            action === 'duplicate' ||
            action === 'show' ||
            action === 'stamp' ||
            action === 'edit' ||
            action === 'create' ||
            action === 'delete')) ||
        (resource === 'ships' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'edit' ||
            action === 'fullInfo' ||
            action === 'canAll' ||
            action === 'create')) ||
        resource === 'assig' ||
        resource === 'ticket' ||
        resource === 'invoice' ||
        (resource === 'act' &&
          (action === 'list' || action === 'show' || action === 'edit')) ||
        resource === 'trains' ||
        resource === 'cash' ||
        (resource === 'users' &&
          (action === 'list' ||
            action === 'create' ||
            action === 'review' ||
            action === 'delete' ||
            action === 'edit' ||
            action === 'admin'))
      ) {
        return Promise.resolve({ can: true });
      }
    }

    // Только бухгалтер
    if (me.role === Role.ACCOUNTANT) {
      if (
        resource === 'dashboard' ||
        resource === 'blanks' ||
        resource === 'airlines' ||
        resource === 'company' ||
        (resource === 'firms' &&
          (action === 'list' ||
            action === 'show' ||
            action === 'editOther' ||
            action === 'showLogist' ||
            action === 'showAssig' ||
            action === 'calendar' ||
            action === 'create' ||
            action === 'edit' ||
            action === 'changeUser')) ||
        resource === 'drivers' ||
        resource === 'sender' ||
        resource === 'vama' ||
        (resource === 'vama_expedition' &&
          (action === 'list' ||
            action === 'all' ||
            action === 'edit' ||
            action === 'confirm')) ||
        resource === 'payments' ||
        (resource === 'payment_lender' &&
          (action === 'list' || action === 'confirm')) ||
        (resource === 'payment_driver' &&
          (action === 'list' || action === 'confirm')) ||
        (resource === 'offers' &&
          (action === 'list' || action === 'stamp' || action === 'show')) ||
        (resource === 'ships' && (action === 'list' || action === 'canAll')) ||
        resource === 'assig' ||
        resource === 'ticket' ||
        (resource === 'act' &&
          (action === 'list' || action === 'show' || action === 'edit')) ||
        resource === 'invoice' ||
        resource === 'trains'
      ) {
        return Promise.resolve({ can: true });
      }
    }

    return Promise.resolve({
      can: false,
      reason: 'Нет доступа',
    });
  },
};

const loginCache: { [key: string]: any } = {};
const userCache: { [key: string]: any } = {};

export const authProvider: AuthProvider = {
  login: async ({ email, password, remember }) => {
    const cacheKey = `${email}:${password}:${remember}`;

    if (loginCache[cacheKey]) {
      return Promise.resolve(loginCache[cacheKey]);
    }

    try {
      let auth;
      if (!remember) {
        auth = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login`,
          { email, password, remember },
        );
      } else {
        auth = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/smslogin`,
          { username: email, password: password.toString() },
        );
      }

      localStorage.setItem('token', auth.data.access_token);
      localStorage.setItem('role', auth.data.role);
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${auth.data.access_token}`,
      };

      const { data: me } = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/me`,
      );

      updateUser(me);

      localStorage.setItem('uuid', me.uuid);
      userCache[cacheKey] = me;

      return Promise.resolve(me);
    } catch (e) {
      return Promise.reject({
        message: 'Вход не выполнен!',
        name: 'Неверный пароль или email',
      });
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('uuid');
    localStorage.clear();
    window.location.replace(process.env.REACT_APP_BACKEND_URL + '/login');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem('token');
    if (token) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    if (role) {
      return Promise.resolve(role);
    }
    return Promise.reject();
  },
  getUserIdentity: async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return Promise.reject();
      }

      const cacheKey = `${token}`;
      if (userCache[cacheKey]) {
        return Promise.resolve(userCache[cacheKey]);
      }

      const user = await axiosInstance.get(
        process.env.REACT_APP_BACKEND_URL + '/api/v1/profile/me',
      );

      // Сохраняем данные пользователя в кэше
      if (!user.data.enabled) {
        throw new Error('User not enabled!');
      }
      userCache[cacheKey] = user.data;

      return Promise.resolve(user.data);
    } catch (e) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('uuid');
      localStorage.clear();

      if (!window.location.href.includes('/register')) {
        window.location.replace(process.env.REACT_APP_BACKEND_URL + '/login');
      }
      return Promise.reject();
    }
  },
};
