import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useNavigation,
  usePermissions,
  useSelect,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  Col,
  CreateButton,
  DatePicker,
  Dropdown,
  Input,
  List,
  MenuProps,
  Modal,
  Row,
  Select,
  SelectProps,
  ShowButton,
  Space,
  Spin,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
  BookOutlined,
  CarOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  CopyFilled,
  FileTextFilled,
  BankFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { ICompanies, IShip, IUser } from 'interfaces';
import React, { Suspense, useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import { ClientType } from 'enums/client.type';
import moment from 'moment';
import { Firms } from 'enums/firms.type';
import { firmColor } from 'utility/getColor';
import { useSearchParams } from '@pankod/refine-react-router-v6';
import { axiosInstance } from 'App';
import { useTranslation } from 'react-i18next';
import ModalEditAct from 'components/modals/editAct';
import dayjs from 'dayjs';
import { getColorShipStatus } from 'utility/getColorShipSatus';
import { ShippingType } from 'enums/shipping.type';

export const ShipsList: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();

  const { mutate: updateShip } = useUpdate({});
  const { show, replace, edit: openEdit } = useNavigation();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [searchParams] = useSearchParams();
  const { mutate: deleteOne } = useDelete({});
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const canAll = useCan({ resource: 'ships', action: 'canAll' }).data?.can;

  let initialFilter: CrudFilters = [
    {
      field: 'ship.createdAt',
      operator: 'gte',
      value: `${dayjs().format('YYYY')}-${dayjs().format('MM')}-01`,
    },
    {
      field: 'ship.createdAt',
      operator: 'lt',
      value: `${dayjs().add(1, 'month').format('YYYY')}-${dayjs()
        .add(1, 'month')
        .format('MM')}-01`,
    },
    { field: 'manager.uuid', value: me?.uuid || null, operator: 'eq' },
  ];

  if (
    !!me?.role &&
    [Role.MANAGER, Role.MANAGER_ASIG, Role.MANAGER_BIG].includes(me.role)
  ) {
    initialFilter = [...initialFilter];
  }
  const {
    tableProps,
    sorter,
    tableQueryResult,
    setFilters,
    setPageSize,
    setCurrent,
  } = useTable<IShip>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialPageSize: pageSizeOptions,
    initialFilter,
  });

  console.log(tableQueryResult);

  const [selectedUuid, setSelectedUuid] = useState<number | null>(null);
  const [openEditAct, setEditAct] = useState<boolean>(false);

  const [modal, contextHolder] = Modal.useModal();

  const items = (value: any): MenuProps['items'] => {
    const orderVisible: MenuProps['items'] = [
      {
        label: !value.order ? 'Заявка получена?' : 'Заявка не получена ? ',
        key: '1',
        icon: <FileDoneOutlined />,
        onClick: () => {
          updateShip(
            {
              resource: 'ships',
              id: value.uuid,
              values: { order: !value.order ? true : false },
              successNotification: {
                description: t('update.titleSuccess'),
                message: t('update.textSuccess'),
                type: 'success',
              },
              errorNotification: {
                description: t('error.createText'),
                message: 'Возникла ошибка',
                type: 'error',
              },
            },
            {
              onSuccess: () => {
                tableQueryResult.refetch();
              },
            },
          );
        },
      },
      {
        key: '2',
        type: 'divider',
      },
    ];

    return [
      ...orderVisible,

      {
        label: 'Редактировать',
        key: '4',
        onClick: () => {
          openEdit('ships', value.uuid);
        },
        icon: <SignatureOutlined />,
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);
    return { items: itms };
  };

  const { options: optionUser } = useSelect<IUser[]>({
    resource: 'users/get/userList',
    optionLabel: 'label',
    optionValue: 'value',
    liveMode: 'auto',
    hasPagination: false,
  });
  const options: any[] = optionUser;

  const setMonth = (value: any) => {
    const month = dayjs(value).format('MM');
    const monthNext = dayjs(value).add(1, 'month').format('MM');
    const year = dayjs(value).format('YYYY');
    const yearNext = dayjs(value).add(1, 'month').format('YYYY');
    if (!value) {
      setFilters([
        {
          field: 'ship.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'ship.createdAt',
          operator: 'lt',
          value: '',
        },
      ]);
    } else {
      setFilters([
        {
          field: 'ship.createdAt',
          operator: 'gte',
          value: `${year}-${month}-01`,
        },
        {
          field: 'ship.createdAt',
          operator: 'lt',
          value: `${yearNext}-${monthNext}-01`,
        },
      ]);
    }
  };

  const fintNumber = (event: any) => {
    const { target } = event;
    const { value } = target;
    if (value) {
      setFilters([
        {
          field: 'manager.uuid',
          operator: 'eq',
          value: me?.role && [Role.MANAGER].includes(me.role) ? me.uuid : '',
        },
        {
          field: 'manager.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'ship.createdAt',
          operator: 'lt',
          value: '',
        },
        { field: 'offer.offerNumber', operator: 'contains', value: value },
      ]);
    } else {
      setFilters([
        ...initialFilter,
        { field: 'offer.offerNumber', operator: 'contains', value: '' },
      ]);
    }
  };

  const addOpt = [{ value: '', label: 'Показать все' }];

  const tagRender: SelectProps['tagRender'] = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };

  return !tableProps.loading && tableProps.dataSource ? (
    <>
      <CanAccess resource="act" action="edit">
        {selectedUuid ? (
          <ModalEditAct
            onCancel={() => {
              setEditAct(false);
              setSelectedUuid(null);
            }}
            open={openEditAct}
            refetch={tableQueryResult.refetch}
            uuid={selectedUuid}
          />
        ) : (
          <></>
        )}
      </CanAccess>
      <CanAccess resource="ship" action="delete">
        {contextHolder}
      </CanAccess>
      <Suspense fallback={<Spin>Загрузка</Spin>}>
        <List
          headerProps={{
            extra: <CreateButton children="Создать заявку" type="primary" />,
          }}
        >
          <Space size={4} style={{ marginBottom: 8 }}>
            {[Firms.REAL, Firms.RCI, Firms.RCI_INVEST, Firms.RCI_PMR].map(
              (firm) => (
                <Tag color={firmColor(firm)}>{firm}</Tag>
              ),
            )}
          </Space>

          <Row gutter={[12, 12]} style={{ marginBottom: '12px' }}>
            <Col>
              <Input
                size="small"
                placeholder="Номер заявки"
                allowClear
                onChange={fintNumber}
              />
            </Col>
            <Col xs={24} md={5}>
              <Select
                showSearch
                style={{ width: '100%' }}
                defaultValue={me?.uuid}
                size="small"
                disabled={!canAll}
                options={[...addOpt, ...options]}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toUpperCase()
                    .includes(input.toLocaleUpperCase())
                }
                onChange={(value) => {
                  setFilters([
                    { field: 'manager.uuid', operator: 'eq', value: value },
                  ]);
                }}
              />
            </Col>
            <Col>
              <DatePicker
                picker="month"
                size="small"
                defaultValue={dayjs()}
                onChange={setMonth}
                format={'MMMM YYYY'}
              />
            </Col>
          </Row>
          <Table
            {...tableProps}
            rowKey="uuid"
            loading={tableProps.loading}
            pagination={{
              ...tableProps.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
              size: 'small',
            }}
            caption={
              <Typography.Text type="secondary" style={{ padding: '4px' }}>
                {`Всего заявок : ${tableQueryResult.data?.total}`}
              </Typography.Text>
            }
            scroll={{ x: 'max-content' }}
            size="small"
          >
            <Table.Column
              key="rs"
              title={<>RS</>}
              align="start"
              render={(_, value: IShip) => (
                <>
                  <ShowButton
                    recordItemId={value.uuid}
                    children={`${value.uuid} / ${value.rs}`}
                    icon={<BookOutlined />}
                    type="primary"
                    size="small"
                  />
                </>
              )}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title={<>Статус</>}
              align="start"
              render={(value) => (
                <>
                  <Tag
                    color={getColorShipStatus(value)}
                    style={{ width: '100%' }}
                  >
                    {value}
                  </Tag>
                </>
              )}
            />
            <Table.Column
              dataIndex="client"
              key="client"
              title={<>Клиент</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value.orgName} />
                </>
              )}
              sorter
            />
            <Table.Column
              dataIndex="pol"
              key="pol"
              title={<>POL</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              dataIndex="pod"
              key="pod"
              title={<>POD</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              dataIndex="destination"
              key="destination"
              title={<>Назначение</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              dataIndex="containerNumber"
              key="containerNumber"
              title={<>№ контейнера</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              // dataIndex="containerType"
              key="containerType"
              title={<>Тип контейнера</>}
              align="start"
              render={(_, value: IShip) => (
                <TextField
                  value={
                    value.typeShipping === ShippingType.FCL
                      ? value.containerType
                      : value.containerVolume
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="order"
              key="order"
              title={<>Заявка</>}
              align="center"
              render={(value) => (
                <>
                  {value ? (
                    <CheckCircleOutlined
                      style={{ color: 'green', fontSize: 32 }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ color: 'red', fontSize: 32 }}
                    />
                  )}
                </>
              )}
            />
            <Table.Column
              dataIndex="mbl"
              key="mbl"
              title={<>MBL</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              dataIndex="hbl"
              key="hbl"
              title={<>HBL</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              dataIndex="etd"
              key="etd"
              title={<>ETD</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              dataIndex="line"
              key="line"
              title={<>Линия</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column
              // dataIndex="priceBuy"
              key="priceBuy"
              title={<>Ставка покупки</>}
              align="end"
              render={(_, offer: IShip) => (
                <>
                  <TextField
                    value={Number(
                      (offer.priceBuy ? +offer.priceBuy : 0) +
                        (offer.aditionalPrice ? +offer.aditionalPrice : 0),
                    ).toLocaleString('ru-RU', { maximumFractionDigits: 2 })}
                  />
                </>
              )}
            />
            <Table.Column
              // dataIndex="priceSell"
              key="priceSell"
              title={<>Ставка продажи</>}
              align="end"
              render={(_, offer: IShip) => (
                <>
                  <TextField
                    value={Number(
                      (offer.priceSell ? +offer.priceSell : 0) +
                        (offer.aditionalPrice ? +offer.aditionalPrice : 0),
                    ).toLocaleString('ru-RU', { maximumFractionDigits: 2 })}
                  />
                </>
              )}
            />
            <Table.Column
              dataIndex="etd"
              key="etd"
              title={<>ETD</>}
              align="start"
              render={(value) => (
                <>
                  <TextField
                    style={{ fontSize: '11px' }}
                    value={moment(value).format('DD.MM.YYYY')}
                  />
                </>
              )}
            />
            <Table.Column
              dataIndex="eta"
              key="eta"
              title={<>ETA</>}
              align="start"
              render={(value) => (
                <>
                  <TextField
                    style={{ fontSize: '11px' }}
                    value={moment(value).format('DD.MM.YYYY')}
                  />
                </>
              )}
            />
            <Table.Column
              dataIndex="shipDocuments"
              key="shipDocuments"
              title={<>Документы</>}
              align="start"
              render={(value) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />

            <Table.Column
              dataIndex="manager"
              key="manager"
              title="RCI"
              width={80}
              align="center"
              render={(value, record: any) =>
                value && value.pictures?.path ? (
                  <Avatar.Group>
                    <Tooltip
                      title={`${value.lastName} ${value.firstName}`}
                      placement="left"
                    >
                      <Avatar
                        style={{ width: '50px', height: '50px' }}
                        src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures.path}`}
                        alt={value.lastName}
                        icon={<UserOutlined />}
                      />
                    </Tooltip>
                    {record.shipAgent && (
                      <Tooltip
                        title={`${record.shipAgent.lastName} ${record.shipAgent.firstName}`}
                        placement="left"
                      >
                        <Avatar
                          style={{ width: '50px', height: '50px' }}
                          src={`${process.env.REACT_APP_BACKEND_URL}${record.shipAgent.pictures.path}`}
                          alt={record.shipAgent.lastName}
                          icon={<UserOutlined />}
                        />
                      </Tooltip>
                    )}
                  </Avatar.Group>
                ) : (
                  <Avatar.Group>
                    <Tooltip
                      title={`${value.lastName} ${value.firstName}`}
                      placement="left"
                    >
                      <Avatar
                        style={{ width: '50px', height: '50px' }}
                        alt={value.lastName}
                        icon={<UserOutlined />}
                      />
                    </Tooltip>
                    {record.shipAgent && (
                      <Tooltip
                        title={`${record.shipAgent.lastName} ${record.shipAgent.firstName}`}
                        placement="left"
                      >
                        <Avatar
                          style={{ width: '50px', height: '50px' }}
                          src={`${process.env.REACT_APP_BACKEND_URL}${record.shipAgent.pictures.path}`}
                          alt={record.shipAgent.lastName}
                          icon={<UserOutlined />}
                        />
                      </Tooltip>
                    )}
                  </Avatar.Group>
                )
              }
            />

            <Table.Column
              key="action"
              title=""
              width={10}
              fixed="right"
              render={(_, value: any) => (
                <>
                  <Dropdown
                    menu={menuProps(value)}
                    arrow
                    trigger={['click']}
                    placement="bottomLeft"
                    destroyPopupOnHide
                  >
                    <Button
                      icon={<EllipsisOutlined />}
                      shape="circle"
                      type="dashed"
                    />
                  </Dropdown>
                </>
              )}
            />
          </Table>
        </List>
      </Suspense>
    </>
  ) : (
    <Spin />
  );
};
