export enum Role {
  SYSTEM = 'Программист',
  ADMIN = 'Администратор',
  DIRECTOR = 'Директор',
  CONTROL = 'Управляющий',
  ACCOUNTANT = 'Бухгалтер',
  MANAGER_BIG = 'Старший менеджер',
  MANAGER = 'Менеджер',
  MANAGER_ASIG = 'Менеджер по страховкам',
}
