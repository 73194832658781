import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  useForm,
  useSelect,
} from '@pankod/refine-antd';
import { CanAccess, useOne } from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function ModalEditAct({
  open,
  onCancel,
  refetch,
  uuid,
}: {
  open: boolean;
  refetch: any;
  onCancel: any;
  uuid: number;
}) {
  const { t } = useTranslation();
  const { formProps, saveButtonProps, queryResult, form } = useForm<ICompanies>(
    {
      resource: 'act',
      action: 'create',
      redirect: false,
      onMutationSuccess: () => {
        refetch();
        onCancel();
      },
      successNotification: {
        description: t('act.success'),
        message: t('act.successText'),
        type: 'success',
      },
      errorNotification: {
        description: t('act.error'),
        message: t('act.errorText'),
        type: 'error',
      },
    },
  );

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const { data, isLoading } = useOne({ resource: 'act', id: uuid });

  useEffect(() => {
    if (!data?.data) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({
      ...data.data,
      summ: data.data.summ ? +data.data.summ : null,
      adds: data.data.adds ? JSON.parse(data.data.adds) : null,
    });
  }, [data?.data]);
  return (
    <Modal
      okButtonProps={saveButtonProps}
      open={open}
      width={580}
      onCancel={onCancel}
      title={t('act.modal.title')}
    >
      <Form
        {...formProps}
        form={form}
        requiredMark
        initialValues={{ offerUuid: uuid, summ: null, adds: [] }}
        layout="vertical"
        size="small"
        scrollToFirstError
        labelCol={{ xs: 8 }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            label={t('act.modal.director')}
            name="director"
            labelAlign="left"
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              placeholder={t('act.modal.directorPlaceholder')}
              size="middle"
            />
          </Form.Item>
          <Form.Item
            label={t('act.modal.summary')}
            name="summ"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t('act.modal.summaryPlaceholder')}
              type="number"
              size="middle"
            />
          </Form.Item>
          <Form.Item label={t('act.modal.work')} labelAlign="left">
            <Form.List name="adds">
              {(fields, { add, remove }, item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      rowGap: 6,
                      flexDirection: 'column',
                    }}
                  >
                    {fields.map((field, idx) => (
                      <Row gutter={8} wrap={false}>
                        <Col flex="auto">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t('act.modal.message.field'),
                              },
                            ]}
                            name={[field.name, 'work']}
                          >
                            <TextArea
                              placeholder={
                                t('act.modal.additional') + ` ${idx + 1}`
                              }
                              rows={1}
                            />
                          </Form.Item>
                        </Col>
                        <Col flex="150px">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t('act.modal.message.field'),
                              },
                            ]}
                            name={[field.name, 'summ']}
                          >
                            <Input
                              size="middle"
                              placeholder={t('act.modal.summary')}
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                        <Col flex="auto">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t('act.modal.message.field'),
                              },
                            ]}
                            name={[field.name, 'summLei']}
                          >
                            <Input
                              placeholder={t('act.modal.additionalSummLei')}
                              type="number"
                              size="middle"
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          flex="auto"
                          style={{ display: 'flex', alignItems: 'baseline' }}
                        >
                          <Button
                            danger
                            size="middle"
                            type="default"
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="primary"
                      style={{ marginBottom: 3 }}
                      onClick={() => add()}
                      block
                    >
                      + {t('act.modal.addWork')}
                    </Button>
                  </div>
                );
              }}
            </Form.List>
          </Form.Item>
          <Form.Item name={'uuid'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'offerUuid'} hidden>
            <Input />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
}
