import {
  Button,
  EmailField,
  Modal,
  Table,
  TagField,
  TextField,
  Tooltip,
} from '@pankod/refine-antd';
import { UserStatus } from 'enums/user.status';
import { IUser } from 'interfaces';
import { SolutionOutlined } from '@ant-design/icons';

import { CSSProperties, useEffect, useState } from 'react';
import { useNavigation, useNotification } from '@pankod/refine-core';
import { axiosInstance } from 'App';

export const ModalAgentList = (props: {
  uuid: number | null;
  open: boolean;
  cancel: () => void;
}) => {
  const { show } = useNavigation();
  const { open: notify } = useNotification();
  const [data, setData] = useState<IUser[]>([]);
  useEffect(() => {
    if (!props.open) return;
    (async () => {
      if (notify) {
        try {
          const result = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/users/agentlist',
            { uuid: props?.uuid },
          );
          setData(result.data);
        } catch (e: any) {
          notify({
            description: 'Ошибка',
            message: 'Не удалось загрузить пользователей',
            type: 'error',
            key: '2',
            undoableTimeout: 20000,
          });
          props.cancel();
        }
      }
    })();
  }, [props.open]);
  return (
    <Modal
      title="Агентский список клиентов"
      style={{ minWidth: '1200px' }}
      open={props.open}
      onCancel={props.cancel}
      footer={[
        <Button key="back" onClick={props.cancel}>
          Выход
        </Button>,
      ]}
    >
      <Table dataSource={data} pagination={false} rowKey="uuid">
        <Table.Column<IUser>
          title="Действия"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Tooltip placement="topLeft" title={'Просмотреть'}>
                <Button
                  onClick={() => {
                    show('users', record.uuid);
                    props.cancel();
                  }}
                  size="small"
                >
                  <SolutionOutlined />
                </Button>
              </Tooltip>
            );
          }}
        />
        {[
          { key: 'lastName', title: 'Фамилия' },
          { key: 'firstName', title: 'Имя' },
          { key: 'orgName', title: 'Организация' },
          { key: 'mainAccount', title: 'Баланс' },
          { key: 'email', title: 'Email' },
        ].map((el) => {
          return (
            <Table.Column
              dataIndex={el.key}
              key={el.key}
              title={el.title}
              render={(value) => <TextField value={value} />}
            />
          );
        })}
        <Table.Column
          dataIndex="status"
          key="status"
          title="Статус"
          render={(value: any, record: any) => {
            let statusColor;
            let tagStyle: CSSProperties = {
              wordBreak: 'break-word',
              border: '1px solid #d2d2d2',
              color: '#535353',
              borderColor: '#d2d2d2',
              backgroundColor: '#f7f7f7',
              padding: '2px 6px 2px 6px',
              fontSize: '12px',
              borderRadius: '2px',
              width: 'fit-content',
            };
            switch (value) {
              case UserStatus.APPROVAL:
                statusColor = 'orange';
                tagStyle = {
                  ...tagStyle,
                  color: '#6a6047',
                  borderColor: '#c9c897',
                  border: '1px solid #9bc997',
                  backgroundColor: '#fff8df',
                };
                break;
              case UserStatus.CLIENT || UserStatus.QUALIFIED:
                statusColor = 'green';
                tagStyle = {
                  ...tagStyle,
                  backgroundColor: '#f5fff0',
                };
                break;
              case UserStatus.DECLINED:
                statusColor = 'volcano';
                tagStyle = {
                  ...tagStyle,
                  color: '#8e6161',
                  borderColor: '#ddbebe',
                  border: '1px solid #ddbebe',
                  backgroundColor: '#fff4f1',
                };
                break;
              default:
                statusColor = '';
            }
            return <div style={tagStyle}>{value}</div>;
          }}
        />
        <Table.Column
          dataIndex="role"
          key="role"
          title="Роль"
          render={(value) => <TagField value={value} />}
        />
      </Table>
    </Modal>
  );
};
