import { Languages } from 'enums/languages.type';

const cargoList: { [key in Languages.RU | Languages.RO]: string[] } = {
  'Русский язык': [
    'Автошины',
    'Алкогольные напитки',
    'Безалкогольные напитки',
    'Бетон',
    'Битум',
    'Бумага',
    'Виноматериалы',
    'Грибы',
    'Домашние вещи',
    'Древесина',
    'Жидкий груз',
    'Заготовки',
    'Запасные части',
    'Зерно',
    'Изделия из кожи',
    'Изделия из металла',
    'Кабель',
    'Канц.товары',
    'Клей',
    'Ковры',
    'Комплектующие',
    'Компьютеры',
    'Кондитерские изделия',
    'Консервы',
    'Контейнер',
    'Косметика',
    'Краска',
    'Лес круглый',
    'Мебель',
    'Медикаменты',
    'Металл в чушках',
    'Металл листовой',
    'Металлы цветные',
    'Металлы чёрные',
    'Металлопрокат',
    'Металлолом',
    'Молоко сухое',
    'Молочные продукты',
    'Мороженое',
    'Моющие средства',
    'Мука',
    'Мусор',
    'Мучные изделия',
    'Мясо',
    'Негабаритный груз',
    'Неопасный груз',
    'Нефтепродукты',
    'Оборудование',
    'Обувь',
    'Овощи',
    'Одежда',
    'Опасный груз',
    'Отходы',
    'Пиво',
    'Пиломатериалы',
    'Пластмасса',
    'Посуда',
    'Проволока',
    'Продукты питания',
    'Промышленный груз',
    'Пряжа',
    'Птица',
    'Резиновые изделия',
    'Рыба',
    'Сантехника',
    'Сахар',
    'Сборный груз',
    'Светильники Сигареты',
    'Скот',
    'Спирт',
    'Спички',
    'Стекло листовое',
    'Стеклотара',
    'Строи-материалы',
    'Сыпучий груз',
    'Сырьё',
    'Табак ',
    'Тара пустая',
    'Ткани',
    'ТНП',
    'Торф',
    'Транспортные средства',
    'Трубы',
    'Удобрения',
    'Упаковка',
    'Фрукты ',
    'Хим.продукты',
    'Хоз.товары',
    'Цемент',
    'Шкуры животных',
    'Ягоды',
    'Электробытовые приборы',
    'Second hand',
    'Генеральный груз',
    'Другой',
  ],
  'Limba Românǎ': [
    'Cauciucuri',
    'Bauturi alcoolice',
    'Bauturi nealcoolice',
    'Beton',
    'Bitum',
    'Hârtie',
    'Materiale vinicole',
    'Ciuperci',
    'Lucruri casnice',
    'Lemn',
    'Marfă lichidă',
    'Spații goale',
    'Piese de schimb',
    'Porumb',
    'Produse din piele',
    'Produse metalice',
    'Cablu',
    'Papetărie',
    'Lipici',
    'Covoare',
    'Accesorii',
    'Calculatoare',
    'Cofetărie',
    'Mancare la conserva',
    'Container',
    'Produse cosmetice',
    'Colorant',
    'rotund de pădure',
    'Mobila',
    'Medicamente',
    'Metal în lingouri',
    'Tablă',
    'Metale neferoase',
    'Metale feroase',
    'Metal laminat',
    'Fier vechi',
    'Lapte praf',
    'Produse lactate',
    'Inghetata',
    'Detergenți',
    'Făină',
    'Gunoi',
    'Produse din făină',
    'Carne',
    'Marfă supradimensionată',
    'Marfă nepericuloasă',
    'Produse petroliere',
    'Echipamente',
    'Pantofi',
    'Legume',
    'îmbrăcăminte',
    'Bunuri periculoase',
    'Deşeuri',
    'Bere',
    'Cherestea',
    'Plastic',
    'Bucate',
    'Sârmă',
    'Alimente',
    'Marfă industrială',
    'Fire',
    'Pasăre',
    'Produse din cauciuc',
    'Peşte',
    'Instalatii sanitare',
    'Zahăr',
    'Marfă consolidată',
    'Lămpi Țigări',
    'Animale',
    'Alcool',
    'Chibrituri',
    'Tablă de sticlă',
    'Recipiente din sticlă',
    'Materiale de construcții',
    'încărcătură vrac',
    'Materii prime',
    'Tutun',
    'Recipientul este gol',
    'Tesaturi',
    'Bunuri de consum',
    'Turbă',
    'Vehicule',
    'Conducte',
    'îngrășăminte',
    'Pachet',
    'Fructe',
    'Produse chimice',
    'Bunuri gospodărești',
    'Ciment',
    'Piei de animale',
    'Fructe de pădure',
    'Electrocasnice',
    'La mâna a doua',
    'Marfă generală',
    'O alta',
  ],
};

export default cargoList;
