import { IUser } from '../../../interfaces';
import { useNotification } from '@pankod/refine-core';
import React, { useState } from 'react';
import { axiosInstance } from '../../../App';
import { Alert, Button, Form, Input, Modal } from '@pankod/refine-antd';

export function TicketModal(props: {
  me: IUser;
  isVisible: boolean;
  setVisible: any;
}) {
  const { open: notify } = useNotification();
  const [ticketButton, setTicketButton] = useState<boolean>(false);
  const onTicket = async (values: any) => {
    let result;
    if (notify) {
      try {
        setTicketButton(true);
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/ticket',
          {
            topic: values.topic,
            text: values.text,
            uuid: props.me.uuid,
          },
        );
        notify({
          description: 'Заявка принята',
          message:
            'Мы ответим вам сообщением на электронную почту в ближайшее время',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
      } catch (e) {
        setTicketButton(false);
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  return (
    <Modal
      title="Создать обращение в поддержку"
      open={props.isVisible}
      onOk={() => {
        props.setVisible(false);
      }}
      onCancel={() => {
        props.setVisible(false);
      }}
      footer={null}
    >
      <Alert
        message={
          'Опишите, пожалуйста, вашу проблему. Мы ответим вам на указанную при регистрации почту в течение 2х рабочих дней.'
        }
        type="info"
      />
      <br />
      <Form layout="vertical" requiredMark={false} onFinish={onTicket}>
        <Form.Item
          label="Тема"
          name="topic"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Текст обращения"
          name="text"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={7} showCount maxLength={1000} />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            block
            disabled={ticketButton}
          >
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
