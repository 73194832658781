import { Languages } from 'enums/languages.type';

const conditions: { [key in Languages.RU | Languages.RO]: string[] } = {
  'Русский язык': [
    '1) Контроль погрузки! ',
    '2) Водитель несут полную материальную ответственность за сохранность груза при транспортировке!',
    '3) Финансовая гарантия по территории Украины за счёт перевозчика',
    '4) Финансовая гарантия по территории Украины за счёт заказчика',
  ],
  'Limba Românǎ': [
    '1) Controlul încărcării și descărcării de către șofer.',
    '2) Șoferul poartă întreaga responsabilitate financiară pentru siguranța încărcăturii în timpul transportului.',
  ],
};

export default conditions;
