export enum ContactType {
  FONDATOR = 'Учредитель',
  DIRECTOR_GEN = 'Генеральный Директор',
  DIRECTOR_COM = 'Коммерческий Директор',
  ADMIN = 'Администратор',
  DIRECTOR = 'Директор',
  CONTABIL_SEF = 'Главный Бухгалтер',
  CONTABIL = 'Бухгалтер',
  MANAGER = 'Менеджер',
  MANAGER_VINZ = 'Менеджер по продажам',
  MANAGER_MARKETING = 'Маркетинговый менеджер',
  MANAGER_AJUNS = 'Помошник менеджера',
  JURIST = 'Юрист/Адвокат',
  OTHER = 'Другое',
}
