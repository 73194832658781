import React, { useEffect } from 'react';
import { Spin } from '@pankod/refine-antd';
import { useGetIdentity, useNavigation } from '@pankod/refine-core';
import { Role } from './enums/role';
import { UserStatus } from './enums/user.status';

export function ErrorPage() {
  const { data: me } = useGetIdentity();
  const { list } = useNavigation();

  useEffect(() => {
    if (me) {
      list('dashboard');
    }
  }, [me, list]);

  if (!me) {
    return <Spin style={{ padding: 20 }} />;
  }

  return null;
}
