import React, { useState } from 'react';
import {
  useGetIdentity,
  useLogout,
  useNotification,
  useTitle,
} from '@pankod/refine-core';
import {
  AntdLayout,
  Button,
  Popconfirm,
  Space,
  Spin,
  Title,
  Typography,
  Image,
  PageHeader,
} from '@pankod/refine-antd';
import { useNavigate } from '@pankod/refine-react-router-v6';
const { Text } = Typography;
export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { data: me, refetch: refetchMe, isError } = useGetIdentity();
  let avatar;
  if (me) {
    avatar = me.pictures;
  }
  const { mutate: logout } = useLogout();
  if (isError) {
    logout();
  }

  return me ? (
    <AntdLayout.Header
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px',
        backgroundColor: '#001528',
        fontSize: '12px',
      }}
    >
      <div
        style={{
          margin: '0 auto',
          height: '100%',
        }}
      >
        {/* <PageHeader style={{ height: '50px', padding: 0 }}> */}
        <a href="https://rci.md">
          <img alt="RCI systems" src="/rci_white.svg" height={'50px'} />
        </a>
        {/* </PageHeader> */}
      </div>
    </AntdLayout.Header>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
