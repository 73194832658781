import { Modal } from '@pankod/refine-antd';
import { useUpdate } from '@pankod/refine-core';
import { VamaStatus } from 'enums/vama.status.type';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ConfirmVama({
  open,
  uuid,
  close,
}: {
  open: boolean;
  uuid: number;
  close: any;
}) {
  const { t } = useTranslation();

  const { mutate } = useUpdate();

  const chengeStatus = async () => {
    mutate({
      resource: 'vama',
      id: uuid,
      values: { uuid: uuid, type: VamaStatus.DONE },
    });
    close();
  };
  return (
    <Modal
      title={t('translation.vama.approve.title')}
      open={open}
      onOk={chengeStatus}
      onCancel={close}
    >
      {t('translation.vama.approve.text')}
    </Modal>
  );
}
