import { Firms } from 'enums/firms.type';

export const firmColor = (firm: Firms): string => {
  switch (firm) {
    case Firms.REAL:
      return '#154468';
    case Firms.RCI:
      return '#108ee9';
    case Firms.RCI_INVEST:
      return '#F68D2E';
    case Firms.RCI_PMR:
      return '#008f19';
    default:
      return '#f50';
  }
};
