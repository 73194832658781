import { IUser } from 'interfaces';
let updateUserFunction: ((userData: IUser) => void) | null = null;

export const setUserUpdateFunction = (updateFn: (userData: IUser) => void) => {
  updateUserFunction = updateFn;
};

export const updateUser = (userData: IUser) => {
  if (updateUserFunction) {
    updateUserFunction(userData);
  } else {
    console.log('Update user function is not set');
  }
};
