import React, { SyntheticEvent, useState } from 'react';
import { useLogin, useNotification } from '@pankod/refine-core';
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Modal,
  Alert,
  TabsProps,
  Tabs,
  FormInstance,
  Space,
  Icons,
} from '@pankod/refine-antd';

import './styles.css';
import { axiosInstance } from '../../App';
const { Text, Title } = Typography;
const { PhoneOutlined, NumberOutlined } = Icons;
export interface ILoginForm {
  email?: string;
  password?: string;
  remember?: boolean;
  phone?: string;
  code?: string;
}
export interface IEmailForm {
  email: string;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

function PasswordLoginForm(props: {
  form: FormInstance<ILoginForm>;
  onFinish: (values: any) => void;
  onClick: () => void;
}) {
  return (
    <Form<ILoginForm>
      layout="vertical"
      form={props.form}
      onFinish={props.onFinish}
      requiredMark={false}
      initialValues={{
        remember: false,
      }}
    >
      <Form.Item
        name="email"
        label="Электронная почта"
        rules={[{ required: true }]}
      >
        <Input size="large" placeholder="your@email.ru" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Пароль"
        rules={[{ required: true }]}
        style={{ marginBottom: '12px' }}
      >
        <Input.Password />
      </Form.Item>
      <Button type="primary" size="large" htmlType="submit" block>
        Войти
      </Button>
    </Form>
  );
}

export const Login: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const { open: notify } = useNotification();
  const [form] = Form.useForm<ILoginForm>();
  // const { mutate: login } = useLogin<ILoginForm>();

  const { open, close } = useNotification();
  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} className="title">
      Авторизация в RCI Systems
    </Title>
  );
  // const onSendRecoverButton = ({ email }: IEmailForm) => {
  //   if (notify) {
  //     setSendDisabled(true);
  //     axiosInstance
  //       .post(process.env.REACT_APP_BACKEND_URL + '/api/v1/auth/send', {
  //         email,
  //       })
  //       .then((response: AxiosResponse) => {
  //         notify({
  //           description: 'Сообщение отправлено',
  //           message:
  //             'В случае, если этот email зарегистрирован на платформе, на него выслано сообщение. Проверьте, пожалуйста, Ваш почтовый ящик.',
  //           type: 'success',
  //           key: '2',
  //           undoableTimeout: 20000,
  //         });
  //       })
  //       .catch((error) => {
  //         if (error.message.indexOf('400') !== -1) {
  //           notify({
  //             description: 'Неправильный email',
  //             message: 'Убедитесь что email введен правильно',
  //             type: 'error',
  //             key: '2',
  //             undoableTimeout: 20000,
  //           });
  //         }
  //       });
  //   }
  // };
  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          background:
            'radial-gradient(50% 50% at 50% 50%, rgb(47 47 47) 0%, rgb(0 0 0) 100%) 0% 0% / cover',
          backgroundSize: 'cover',
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <a href={'/'}>
                <img src="./rci_white.svg" alt="Envy Logo" width="250" />
              </a>
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              {params.recovered && (
                <>
                  <Alert
                    showIcon
                    description={
                      'Вы успешно восстановили пароль. Пожалуйста, войдите в систему с новым паролем.'
                    }
                    type="info"
                  />
                  <br />
                </>
              )}
              <PasswordLoginForm
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                onClick={showModal}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
