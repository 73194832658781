import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetLocale,
  useNavigation,
  useSetLocale,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { ClientType } from 'enums/client.type';
import { CurrencyType } from 'enums/currency.type';
import { Firms } from 'enums/firms.type';
import { Languages } from 'enums/languages.type';
import { ICompanies, IOffer } from 'interfaces';
import moment from 'moment';
import { off } from 'process';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const checkFirm = (firm: Firms) => {
  switch (firm) {
    case Firms.RCI:
      return 'rci';
    case Firms.REAL:
      return 'real';
    case Firms.RCI_INVEST:
      return 'invest';
    case Firms.RCI_PMR:
      return 'rci_pmr';
    default:
      return 'real';
  }
};
const checkCurrency = (currency: CurrencyType) => {
  switch (currency) {
    case CurrencyType.MDL:
      return 'mdl';
    case CurrencyType.EUR:
      return 'eur';
    case CurrencyType.USD:
      return 'usd';
    default:
      return 'mdl';
  }
};

export const ActShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const ids = id?.split('&')[0];
  const type: ClientType =
    ClientType.FIRM === id?.split('&')[1]
      ? ClientType.FIRM
      : ClientType.TRANSPORT;

  const [offerType, setOfferType] = useState<ClientType>(type);
  const [hideBtn, setHideBtn] = useState<boolean>(true);

  const {
    queryResult: { data: response, isLoading, refetch: refetchOffer },
  } = useShow<IOffer>({ resource: 'offers', id: ids });

  const { t } = useTranslation();

  if (!response) {
    return <Spin>Загрузка</Spin>;
  }
  const { data } = response;

  const leftSide = [
    {
      title: 'blank.typeData',
      data: [
        { title: 'blank.type', text: data.transportType },
        { title: 'blank.numberCar', text: data.carNumber },
        {
          title: 'blank.driverData',
          text:
            data.driverContact && data.driverContact !== ''
              ? JSON.parse(data.driverContact).map(
                  (contact: { fio: string; phone: string }) => (
                    <span>
                      {contact.fio} {contact.phone}
                    </span>
                  ),
                )
              : '',
        },
        {
          title: 'blank.docs',
          text:
            data.documents && data.documents !== ''
              ? JSON.parse(data.documents).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.documents).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
      ],
    },
    {
      title: 'blank.cargoInfo',
      data: [
        { title: 'blank.mass', text: `${data.mass} ${data.addMass}` },
        { title: 'blank.volum', text: `${data.volum} ${data.addVolum}` },
        { title: 'blank.typeCargo', text: data.characterCargo },
        { title: 'blank.temperature', text: data.thermoMode },
      ],
    },
    {
      title: 'blank.condition',
      data: [
        {
          title: 'blank.srecificalCondition',
          text:
            data.conditions && data.conditions !== ''
              ? JSON.parse(data.conditions).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.conditions).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
        {
          title: 'blank.transportCondition',
          text: t('blank.transportCondition_text', {
            lng: data.language === Languages.RU ? 'ru' : 'ro',
          }),
        },
        {
          title: 'blank.price',
          text: `${Number(
            offerType === ClientType.FIRM ? data.priceFirm : data.priceDriver,
          ).toLocaleString('ru-RU')}  (${
            offerType === ClientType.FIRM
              ? data.textPriceLender
              : data.textPriceDriver
          }) ${
            offerType === ClientType.FIRM
              ? data.currencyFirm
              : data.currencyDriver
          }`,
        },
        {
          title: 'blank.term',
          text: `${
            offerType === ClientType.FIRM
              ? t('blank.perecislenie.firm', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
              : t('blank.perecislenie.transport', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
          } ${
            offerType === ClientType.FIRM
              ? `${data.firmFrom}-${data.firmTo}`
              : `${data.driverFrom}-${data.driverTo}`
          } ${
            offerType === ClientType.FIRM
              ? t('blank.perecislenie2.firm', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
              : t('blank.perecislenie2.transport', {
                  lng: data.language === Languages.RU ? 'ru' : 'ro',
                })
          }`,
        },
        {
          title: 'blank.contactRCI',
          text: `${data.user.lastName} ${data.user.firstName} ${data.user.phone}`,
        },
      ],
    },
  ];

  const cancelEditPage = () => {
    document.designMode = 'off';
    setHideBtn(true);
  };
  const editPage = () => {
    document.designMode = 'on';
    setHideBtn(false);
  };

  function save(divName: any) {
    const printContents = document.getElementById(divName)?.innerHTML;
    const originalContents = document.body.innerHTML;
    const popupWin = window.open('', '_blank');
    if (divName && printContents && popupWin) {
      popupWin.document.open();
      popupWin.document.write(
        `<html><head ><title>АКТ №${data.offerNumber}_${offerType}</title><style type="text/css">*{box-sizing:border-box;line-height:1rem;font-size:16px;-webkit-print-color-adjust: exact;} table{border-collapse:collapse} td{font-size: 10px,padding:6px auto} </style></head><body style="box-sizing: border-box;font-family: sans-serif;margin: 0;padding: 0;position: relative">` +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
      popupWin.print();
      // popupWin.onafterprint = function () {
      //   popupWin.close();
      // };
    }
  }

  const adds = data.act.adds ? JSON.parse(data.act.adds) : [];

  const totalFirm =
    +data.act.summ +
    +adds.reduce((sum: number, elem: any) => {
      return sum + elem.summLei;
    }, 0);
  const totalTransport =
    +data.priceDriver +
    +adds.reduce((sum: number, elem: any) => {
      return sum + elem.summ;
    }, 0);

  return !isLoading && data ? (
    <CanAccess resource="offers" action="show">
      <div
        style={{
          overflow: 'auto',
          paddingBottom: '70px',
        }}
      >
        <>
          <Row gutter={[20, 20]}>
            <Col>
              <Space size={12}>
                <Button type="primary" onClick={() => save('printable')}>
                  {t('download.act')}
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    offerType === ClientType.FIRM
                      ? setOfferType(ClientType.TRANSPORT)
                      : setOfferType(ClientType.FIRM)
                  }
                >
                  Акт для{' '}
                  {offerType === ClientType.FIRM
                    ? ClientType.TRANSPORT
                    : ClientType.FIRM}
                </Button>
                {hideBtn ? (
                  <Tooltip title={t('can_edit_only_this_page')}>
                    <Button type="primary" onClick={editPage}>
                      Править
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('cancel_edit_mode')}>
                    <Button type="primary" danger onClick={cancelEditPage}>
                      Выключить режим правок
                    </Button>
                  </Tooltip>
                )}
                <CanAccess resource="offers" action="edit">
                  <Button
                    type="primary"
                    //   style={{ color: '#fff', '&:hover': { color: '#fff' } }}
                  >
                    Изменить
                  </Button>
                </CanAccess>
              </Space>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Progress size={180} />
                </div>
              ) : (
                <div className="printable" id="printable">
                  <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '28.9cm',
                      maxHeight: '28.9cm',
                      backgroundColor: '#ffffff',
                      padding: '0.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      margin: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    {/* ЛОГОТИП */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: '10px',
                      }}
                    >
                      <div>
                        {offerType === ClientType.FIRM && (
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/img/ico_logo.${checkFirm(data.company)}.png`}
                            alt="Real Cargo Logo"
                            style={{ width: '300px' }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: '600',
                        }}
                      >
                        {moment(data.vama.datePayment).format('DD.MM.YYYY')}
                      </div>
                    </div>
                    {/* ПЕРВЫЙ БЛОК */}
                    <div
                      style={{
                        marginTop: '16px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography.Text
                        style={{ lineHeight: '1.1em', fontWeight: 600 }}
                      >
                        АКТ №{data.offerNumber} выполненных работ к договору №
                        {data.offerNumber} от{' '}
                        {moment(data.createdAt).format('DD.MM.YYYY')}
                      </Typography.Text>
                    </div>
                    {/* Второй блок карточка */}
                    <div
                      style={{
                        marginBottom: '16px',
                        border: '1px solid #cfcfcf',
                        borderTop: 0,
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          background: '#001d3f',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#fff',
                          padding: '4px',
                          fontSize: '16px',
                        }}
                      >
                        Соглашение сторон
                      </div>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              lineHeight: '1em',
                              color: '#696969',
                              padding: '8px',
                              borderBottom: '1px solid #cfcfcf',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {offerType === ClientType.TRANSPORT ? (
                              <div
                                style={{
                                  fontWeight: 500,
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                  color: 'rgb(105, 105, 105)',
                                  textAlign: 'justify',
                                }}
                              >
                                Мы , нижеподписавшиеся ,{' '}
                                <b> S.R.L. "{data.company}" </b> , в лице
                                директора <b>IOSELIANI</b> , именуемый в
                                дальнейшем <b>" Заказчик/Экспедитор "</b> ,
                                действующий на основании Устава и{' '}
                                <b> {data.offerDriver.orgName}</b>, в лице
                                директора <b>{data.act.director}</b> , именуемый
                                в дальнейшем <b>" Перевозчик "</b> , действующий
                                на основании Устава , составили данный акт
                                выполненых работ по перевозке груза и оказание
                                экспедиторских услуг :
                              </div>
                            ) : (
                              <div
                                style={{
                                  fontWeight: 500,
                                  lineHeight: '18px',
                                  fontSize: '16px',
                                  color: 'rgb(105, 105, 105)',
                                  textAlign: 'justify',
                                }}
                              >
                                Мы , нижеподписавшиеся ,{' '}
                                <b> S.R.L. {data.company} </b> , в лице
                                директора <b>IOSELIANI</b> , именуемый в
                                дальнейшем <b>" Исполнитель - Экспедитор "</b> ,
                                действующий на основании Устава и{' '}
                                {data.offerLender.orgName}, в лице директора ,
                                именуемый в дальнейшем <b>" Заказчик "</b> ,
                                действующий на основании Устава , составили
                                данный акт выполненых работ по перевозке груза и
                                оказание экспедиторских услуг :
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* Таблица */}
                      <div
                        style={{
                          border: '1px solid #cfcfcf',
                          borderTop: 0,
                          borderRadius: '5px',
                          overflow: 'hidden',
                          marginBottom: '8px',
                        }}
                      >
                        <div style={{ color: '#001d3f' }}>
                          <table style={{ border: 'collapse', width: '100%' }}>
                            <thead>
                              <tr style={{ background: '#001d3f' }}>
                                <td
                                  align="center"
                                  style={{ color: '#fff', padding: '6px' }}
                                >
                                  №
                                </td>
                                {/* <td
                                  align="center"
                                  style={{
                                    lineHeight: '1em',
                                    color: '#fff',
                                    whiteSpace: 'pre-line',
                                    padding: '6px',
                                  }}
                                >
                                  {'Date of loading\nДата загрузки'}
                                </td> */}
                                <td
                                  align="center"
                                  style={{
                                    lineHeight: '1em',
                                    color: '#fff',
                                    whiteSpace: 'pre-line',
                                    padding: '6px',
                                  }}
                                >
                                  {'Маршрут'}
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    lineHeight: '1em',
                                    color: '#fff',
                                    whiteSpace: 'pre-line',
                                    padding: '6px',
                                  }}
                                >
                                  {'Рег. номер автомобиля'}
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    lineHeight: '1em',
                                    color: '#fff',
                                    whiteSpace: 'pre-line',
                                    padding: '6px',
                                  }}
                                >
                                  {'Наименование груза'}
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    lineHeight: '1em',
                                    color: '#fff',
                                    whiteSpace: 'pre-line',
                                    padding: '6px',
                                  }}
                                >
                                  Сумма (
                                  {offerType === ClientType.FIRM
                                    ? data.currencyFirm
                                    : data.currencyDriver}
                                  )
                                </td>
                                {offerType === ClientType.FIRM && (
                                  <td
                                    align="center"
                                    style={{
                                      lineHeight: '1em',
                                      color: '#fff',
                                      whiteSpace: 'pre-line',
                                      padding: '6px',
                                    }}
                                  >
                                    {'Сумма (MDL)'}
                                  </td>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  style={{ color: '#001d3f', padding: '4px' }}
                                >
                                  1
                                </td>

                                <td
                                  align="center"
                                  style={{ color: '#001d3f', padding: '4px' }}
                                >
                                  {data.way}
                                </td>

                                <td
                                  align="center"
                                  style={{ color: '#001d3f', padding: '4px' }}
                                >
                                  {data.carNumber}
                                </td>
                                <td
                                  align="center"
                                  style={{ color: '#001d3f', padding: '4px' }}
                                >
                                  {data.characterCargo}
                                </td>
                                <td
                                  align="center"
                                  style={{ color: '#001d3f', padding: '4px' }}
                                >
                                  {Number(
                                    offerType === ClientType.FIRM
                                      ? data.priceFirm
                                      : data.priceDriver,
                                  ).toLocaleString('ru-Ru', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                {offerType === ClientType.FIRM && (
                                  <td
                                    align="center"
                                    style={{ color: '#001d3f', padding: '4px' }}
                                  >
                                    {Number(data.act.summ).toLocaleString(
                                      'ru-Ru',
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      },
                                    )}
                                  </td>
                                )}
                              </tr>
                              {adds.map((el: any, id: number) => {
                                return (
                                  <tr>
                                    <td
                                      align="center"
                                      style={{
                                        color: '#001d3f',
                                        padding: '4px',
                                        borderTop: '1px solid #d0d0d0',
                                      }}
                                    >
                                      {id + 2}
                                    </td>
                                    <td
                                      align="center"
                                      style={{
                                        color: '#001d3f',
                                        padding: '4px',
                                        borderTop: '1px solid #d0d0d0',
                                      }}
                                    >
                                      {el.work}
                                    </td>
                                    <td
                                      colSpan={2}
                                      style={{
                                        padding: '4px',
                                        borderTop: '1px solid #d0d0d0',
                                      }}
                                    ></td>
                                    <td
                                      align="center"
                                      style={{
                                        padding: '4px',
                                        borderTop: '1px solid #d0d0d0',
                                      }}
                                    >
                                      {Number(el.summ)}
                                    </td>
                                    {offerType === ClientType.FIRM && (
                                      <td
                                        align="center"
                                        style={{
                                          padding: '4px',
                                          borderTop: '1px solid #d0d0d0',
                                        }}
                                      >
                                        {Number(el.summLei)}
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}

                              <tr>
                                <td
                                  colSpan={
                                    offerType === ClientType.FIRM ? 5 : 4
                                  }
                                  style={{
                                    padding: '4px',
                                    borderTop: '1px solid #d0d0d0',
                                  }}
                                />
                                <td
                                  style={{
                                    color: '#001d3f',
                                    padding: '4px',
                                    borderTop: '1px solid #d0d0d0',
                                  }}
                                >
                                  <div>
                                    <div style={{ display: 'flex' }}>
                                      <div
                                        style={{
                                          whiteSpace: 'pre-line',
                                          marginRight: '20px',
                                        }}
                                      >
                                        {'Amount\nИтого'}
                                      </div>
                                      <div style={{ whiteSpace: 'pre-line' }}>
                                        {`${Number(
                                          offerType === ClientType.FIRM
                                            ? totalFirm
                                            : totalTransport,
                                        ).toLocaleString('ru-Ru', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        })}\n${Number(
                                          offerType === ClientType.FIRM
                                            ? totalFirm
                                            : totalTransport,
                                        ).toLocaleString('ru-Ru', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        })}`}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div
                        style={{
                          margin: '24px 0',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography.Text
                          style={{ fontSize: '16px', fontWeight: 600 }}
                        >
                          Претензий стороны не имеют.
                        </Typography.Text>
                      </div>

                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            gap: '12px',
                            flexDirection:
                              offerType === ClientType.FIRM
                                ? 'row'
                                : 'row-reverse',
                          }}
                        >
                          <div style={{ width: '50%' }}>
                            <div
                              style={{
                                border: '1px solid #e0e0e0',
                                overflow: 'hidden',
                                borderRadius: '8px',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  background: '#001d3f',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  color: '#fff',
                                }}
                              >
                                <Typography style={{ color: '#fff' }}>
                                  {offerType === ClientType.FIRM
                                    ? t('blank.zakazcik', {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })
                                    : t('blank.transport', {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  padding: 10,
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    fontWeight: 'bold',
                                    marginBottom: '100px',
                                    whiteSpace: 'pre-line',
                                    display: 'inline-block',
                                    lineHeight: '1.3em',
                                  }}
                                >
                                  {offerType === ClientType.FIRM
                                    ? data.offerLender.orgName
                                    : data.offerDriver.orgName}
                                </Typography.Text>
                                <Typography.Text style={{}}>
                                  Директор{' '}
                                  <b>
                                    <span
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      {data.act.director}
                                    </span>
                                  </b>
                                </Typography.Text>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '50%' }}>
                            <div
                              style={{
                                border: '1px solid #e0e0e0',
                                borderRadius: '8px',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  background: '#001d3f',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: '#001d3f',
                                  padding: 1,
                                }}
                              >
                                <Typography style={{ color: '#fff' }}>
                                  {offerType === ClientType.FIRM
                                    ? t('blank.expeditor', {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })
                                    : t('blank.clientExpeditor', {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  padding: 10,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  position: 'relative',
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    fontWeight: 'bold',
                                    marginBottom: '100px',
                                  }}
                                >
                                  {t(`${checkFirm(data.company)}.label`, {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography.Text>
                                <Typography.Text>
                                  Директор{' '}
                                  <b>
                                    <span
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      Ioseliani A.
                                    </span>
                                  </b>
                                </Typography.Text>
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/img/stamp_${checkFirm(data.company)}.png`}
                                  alt="Печать фирмы"
                                  style={{
                                    position: 'absolute',
                                    right: '50px',
                                    transform: 'rotate(-15deg)',
                                    width: '60mm',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </>
      </div>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
