import { Languages } from 'enums/languages.type';

const transportTypes: { [key in Languages.RU | Languages.RO]: string[] } = {
  'Русский язык': [
    'Автовоз',
    'Автовышка',
    'Автокран',
    'Бензовоз',
    'Бетоновоз',
    'Бортовая',
    'Джамбо',
    'Зерновая',
    'Изотерма',
    'Контейнеровоз',
    'Лесовоз',
    'Ломовоз',
    'Микроавтобус',
    'Молоковоз',
    'Мусоровоз',
    'Пикап',
    'Платформа',
    'Рефрижератор',
    'Самосвал',
    'Скотовоз',
    'Тент',
    'Тент (бок. загрузка)',
    'Тент (верхн. загрузка)',
    'Трубовоз',
    'Тягач',
    'Уточнаяется...',
    'Цельномет',
    'Цементовоз',
    'Цистерна пищевая',
    'Другое...',
  ],
  'Limba Românǎ': [
    'Alt',
    'Autobasculanta',
    'Autocamion cu obloane inalte',
    'Autocamion de transportat conteinelor',
    'Autocamion pentru beton',
    'Autocamioneta',
    'Autocisterna pentru petrol',
    'Automacara',
    'Automobil de livrare a laptelui',
    'Automobil de transportare a cerealelor',
    'Automobil de transportare a rangii',
    'Automobil pentru transportarea vitelor',
    'Autoremorce-platforma',
    'Autotren',
    'Camion de transportat gunoi',
    'Camion de transportat tevi',
    'Camion foristier',
    'Camion-cisterna pentru cement',
    'Camion-frigider',
    'Cap tractor',
    'Cu prelata (incarcare lateral)',
    'Cu prelata (incarcare pe sus)',
    'Furgon',
    'Isotermic',
    'Jumbo',
    'Minibus',
    'Prelata',
    'Transportator de automobile',
  ],
};

export default transportTypes;
