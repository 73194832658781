import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetLocale,
  useNavigation,
  useSetLocale,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { ClientType } from 'enums/client.type';
import { Firms } from 'enums/firms.type';
import { Languages } from 'enums/languages.type';
import { ICompanies, IOffer } from 'interfaces';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const checkFirm = (firm: Firms) => {
  switch (firm) {
    case Firms.RCI:
      return 'rci';
    case Firms.REAL:
      return 'real';
    case Firms.RCI_INVEST:
      return 'invest';
    default:
      return 'real';
  }
};

export const TickedShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const ids = id?.split('&')[0];

  const {
    queryResult: { data: response, isLoading, refetch: refetchOffer },
  } = useShow<IOffer>({ resource: 'offers', id: ids });

  const { t } = useTranslation();

  if (!response) {
    return <Spin>Загрузка</Spin>;
  }
  const { data } = response;

  const leftSide = [
    {
      title: 'blank.typeData',
      data: [
        { title: 'blank.type', text: data.transportType },
        { title: 'blank.numberCar', text: data.carNumber },
        {
          title: 'blank.driverData',
          text:
            data.driverContact && data.driverContact !== ''
              ? JSON.parse(data.driverContact).map(
                  (contact: { fio: string; phone: string }) => (
                    <span>
                      {contact.fio} {contact.phone}
                    </span>
                  ),
                )
              : '',
        },
        {
          title: 'blank.docs',
          text:
            data.documents && data.documents !== ''
              ? JSON.parse(data.documents).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.documents).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
      ],
    },
    {
      title: 'blank.cargoInfo',
      data: [
        { title: 'blank.mass', text: `${data.mass} ${data.addMass}` },
        { title: 'blank.volum', text: `${data.volum} ${data.addVolum}` },
        { title: 'blank.typeCargo', text: data.characterCargo },
        { title: 'blank.temperature', text: data.thermoMode },
      ],
    },
    {
      title: 'blank.condition',
      data: [
        {
          title: 'blank.srecificalCondition',
          text:
            data.conditions && data.conditions !== ''
              ? JSON.parse(data.conditions).map((doc: string, idx: number) => (
                  <span>
                    {doc}
                    {idx === JSON.parse(data.conditions).length - 1 ? '' : ', '}
                  </span>
                ))
              : '',
        },
        {
          title: 'blank.transportCondition',
          text: t('blank.transportCondition_text', {
            lng: data.language === Languages.RU ? 'ru' : 'ro',
          }),
        },
        {
          title: 'blank.price',
          text: '1234',
        },
        {
          title: 'blank.term',
          text: '12345',
        },
        {
          title: 'blank.contactRCI',
          text: `${data.user.lastName} ${data.user.firstName} ${data.user.phone}`,
        },
      ],
    },
  ];

  function save(divName: any) {
    const printContents = document.getElementById(divName)?.innerHTML;
    const originalContents = document.body.innerHTML;
    const popupWin = window.open('', '_blank');
    if (divName && printContents && popupWin) {
      popupWin.document.open();
      popupWin.document.write(
        '<html><head ><title>Квитанция перевозчику заявка №${data.offerNumber}</title><style type="text/css">*{box-sizing:border-box;line-height:1rem;font-size:16px;-webkit-print-color-adjust: exact;} td{font-size: 10px} </style></head><body style="box-sizing: border-box;font-family: sans-serif;margin: 0;padding: 0;position: relative">' +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
      popupWin.print();
      // popupWin.onafterprint = function () {
      //   popupWin.close();
      // };
    }
  }

  return !isLoading && data ? (
    <CanAccess resource="offers" action="show">
      <div
        style={{
          overflow: 'auto',
          paddingBottom: '70px',
        }}
      >
        <>
          <Row gutter={[20, 20]}>
            <Col>
              <Space size={12}>
                <Button type="primary" onClick={() => save('printable')}>
                  {t('download.ticket')}
                </Button>
              </Space>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Progress size={180} />
                </div>
              ) : (
                <div
                  className="printable"
                  id="printable"
                  style={{ fontFamily: 'Arial' }}
                >
                  <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '29.9cm',
                      maxHeight: '29.9cm',
                      backgroundColor: '#ffffff',
                      padding: '0.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ margin: '20px', display: 'flex' }}>
                      <div
                        style={{
                          width: '60%',
                          borderRight: '2px solid black',
                          paddingTop: '40px',
                          paddingBottom: '40px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '40px',
                            border: '1px solid black',
                          }}
                        >
                          <div
                            style={{
                              padding: '5px auto',
                              border: '1px solid black',
                              display: 'flex',
                              height: '25px',
                              width: '70px',
                              textAlign: 'center',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            Код
                          </div>
                          <div
                            style={{
                              padding: '5px auto',
                              border: '1px solid black',
                              display: 'flex',
                              height: '25px',
                              width: '70px',
                            }}
                          ></div>
                          <div
                            style={{
                              padding: '5px auto',
                              border: '1px solid black',
                              display: 'flex',
                              height: '25px',
                              width: '70px',
                            }}
                          ></div>
                          <div
                            style={{
                              padding: '5px auto',
                              border: '1px solid black',
                              display: 'flex',
                              height: '25px',
                              width: '70px',
                            }}
                          ></div>
                          <div
                            style={{
                              padding: '5px auto',
                              border: '1px solid black',
                              display: 'flex',
                              height: '35px',
                              width: '70px',
                            }}
                          ></div>
                        </div>

                        <div
                          style={{
                            marginTop: '70px',
                            width: '70%',
                            textAlign: 'center',
                            borderBottom: '1px solid black',
                            position: 'relative',
                          }}
                        >
                          <strong>"{data.company}" S.R.L.</strong>
                          <span
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              bottom: '-15px',
                              fontSize: '10px',
                            }}
                          >
                            (организация)
                          </span>
                        </div>
                        <div
                          style={{
                            marginTop: '50px',
                            width: '100%',
                            textAlign: 'center',
                            borderBottom: '1px solid black',
                            position: 'relative',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              bottom: '-15px',
                              fontSize: '10px',
                            }}
                          >
                            (структурное подразделение)
                          </span>
                        </div>

                        <div
                          style={{
                            marginTop: '20px',
                            paddingTop: '20px',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'normal',
                            alignItems: 'end',
                            height: '70px',
                          }}
                        >
                          <div style={{ position: 'absolute', right: '0' }}>
                            <div
                              style={{
                                padding: '5px auto',
                                border: '2px solid black',
                                display: 'flex',
                                height: '45px',
                                width: '70px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            ></div>
                            <div
                              style={{
                                padding: '5px auto',
                                border: '2px solid black',
                                display: 'flex',
                                height: '25px',
                                width: '70px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            ></div>
                          </div>
                          <div style={{ fontSize: '18px', fontWeight: '500' }}>
                            ПРИХОДНЫЙ КАССОВЫЙ ОРДЕР
                          </div>
                        </div>
                        <div style={{ marginTop: '16px' }}>
                          <table
                            style={{
                              border: '1px solid black',
                              borderCollapse: 'collapse',
                              width: '100%',
                              fontSize: '10px',
                              textAlign: 'center',
                            }}
                          >
                            <tr>
                              <td
                                rowSpan={2}
                                style={{
                                  border: '1px solid black',
                                }}
                                width={10}
                              >
                                Кол-
                                <br />
                                во
                              </td>
                              <td
                                colSpan={4}
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                Направление
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                                rowSpan={2}
                              >
                                Сумма <br /> {data.currencyDriver}
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                                width={30}
                                rowSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                                width={30}
                              ></td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                Номера
                                <br />
                                машины
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                Откуда
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                Куда
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                1
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              ></td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                {data.carNumber}
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                {data.wayFrom}
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                {data.wayTo}
                              </td>
                              <td
                                style={{
                                  border: '1px solid black',
                                }}
                              >
                                {data.priceDriver}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div style={{ marginTop: '24px' }}>
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                            >
                              Принято от : &nbsp;
                            </div>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                              }}
                            >
                              {data.company} S.R.L.
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '16px' }}>
                            <div
                              style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                            >
                              Получил : &nbsp;
                            </div>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                              }}
                            >
                              {data.offerDriver.orgName}
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '16px' }}>
                            <div
                              style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                            >
                              Водитель : &nbsp;
                            </div>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                              }}
                            >
                              {data.driverContact !== ''
                                ? JSON.parse(data.driverContact).map(
                                    (contact: any) => (
                                      <>
                                        {contact.fio} <br />
                                      </>
                                    ),
                                  )
                                : ''}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '16px',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                            >
                              Сумма : &nbsp;
                            </div>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                            >
                              {data.priceDriver} {data.currencyDriver}
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '50%',
                                  transform: 'translateX(50%)',
                                  fontSize: '10px',
                                  bottom: '-13px',
                                }}
                              >
                                (Прописью)
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '16px' }}>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                              }}
                            >
                              {data.textPriceDriver.toLocaleUpperCase()}{' '}
                              {data.currencyDriver}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '16px',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                            >
                              Основание : &nbsp;
                            </div>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                            >
                              Транспортировка груза по маршруту:
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '16px',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                            >
                              {data.way}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '16px',
                              justifyContent: 'end',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                                marginRight: '12px',
                              }}
                            >
                              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                            </div>
                            <div
                              style={{
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                                marginRight: '12px',
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div
                              style={{
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                                marginRight: '12px',
                              }}
                            >
                              &nbsp;&nbsp;{moment().format('YYYY')}&nbsp;&nbsp;
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '16px',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                            >
                              Получил : &nbsp;
                            </div>
                            <div
                              style={{
                                width: '100px',
                                marginLeft: 'auto',
                                marginRight: '12px',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '50%',
                                  transform: 'translateX(50%)',
                                  fontSize: '10px',
                                  bottom: '-13px',
                                }}
                              >
                                (Подпись)
                              </div>
                            </div>
                            <div
                              style={{
                                marginLeft: 'auto',
                                marginRight: '12px',
                                width: '200px',
                                borderBottom: '2px solid black',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                            >
                              {data.driverContact !== ''
                                ? JSON.parse(data.driverContact).map(
                                    (contact: any) => (
                                      <>
                                        {contact.fio} <br />
                                      </>
                                    ),
                                  )
                                : ''}
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '50%',
                                  transform: 'translateX(50%)',
                                  fontSize: '10px',
                                  bottom: '-13px',
                                }}
                              >
                                (Имя Фамилия)
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '40%',
                          padding: '20px 0 20px 20px',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            marginTop: '16px',
                            width: '100%',
                            textAlign: 'center',
                            borderBottom: '2px solid black',
                          }}
                        >
                          {data.company} S.R.L.
                          <div
                            style={{
                              position: 'absolute',
                              right: '50%',
                              transform: 'translateX(50%)',
                              fontSize: '10px',
                              bottom: '-13px',
                            }}
                          >
                            (организация)
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: '32px',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 500,
                          }}
                        >
                          КВИТАНЦИЯ
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            к приходному кассовому ордер : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          ></div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            от : &nbsp;
                          </div>
                          <div
                            style={{
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              marginRight: '10px',
                            }}
                          >
                            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                          </div>
                          <div
                            style={{
                              width: '100px',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              marginRight: '10px',
                              position: 'relative',
                            }}
                          ></div>
                          <div
                            style={{
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          >
                            &nbsp;&nbsp;{moment().format('YYYY')}г.&nbsp;&nbsp;
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Принято от : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {data.company} S.R.L.
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          ></div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Получил : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {data.offerDriver.orgName}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Водитель : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {data.driverContact !== ''
                              ? JSON.parse(data.driverContact).map(
                                  (contact: any) => (
                                    <>
                                      {contact.fio} <br />
                                    </>
                                  ),
                                )
                              : ''}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Основание : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            Транспортировка груза
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            По маршруту : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {data.way}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Регистрационные номера : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {data.carNumber}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Сумма : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {Number(data.priceDriver).toLocaleString('ru-Ru', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                            {data.currencyDriver}
                            <div
                              style={{
                                position: 'absolute',
                                right: '50%',
                                transform: 'translateX(50%)',
                                fontSize: '10px',
                                bottom: '-13px',
                              }}
                            >
                              (цифрами)
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            {data.textPriceDriver.toUpperCase()}{' '}
                            {data.currencyDriver}
                            <div
                              style={{
                                position: 'absolute',
                                right: '50%',
                                transform: 'translateX(50%)',
                                fontSize: '10px',
                                bottom: '-13px',
                              }}
                            >
                              (прописью)
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            &nbsp;
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            &nbsp;
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              fontSize: '10px',
                            }}
                          >
                            &nbsp;
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              marginRight: '12px',
                            }}
                          >
                            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                          </div>
                          <div
                            style={{
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              marginRight: '12px',
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <div
                            style={{
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                              marginRight: '12px',
                            }}
                          >
                            &nbsp;&nbsp;{moment().format('YYYY')}&nbsp;&nbsp;
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                          >
                            Получил : &nbsp;
                          </div>
                          <div
                            style={{
                              width: '100px',
                              marginLeft: 'auto',
                              marginRight: '12px',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                right: '50%',
                                transform: 'translateX(50%)',
                                fontSize: '10px',
                                bottom: '-13px',
                              }}
                            >
                              (Подпись)
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: 'auto',
                              marginRight: '12px',
                              width: '220px',
                              borderBottom: '2px solid black',
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          >
                            {data.driverContact !== ''
                              ? JSON.parse(data.driverContact).map(
                                  (contact: any) => (
                                    <>
                                      {contact.fio} <br />
                                    </>
                                  ),
                                )
                              : ''}
                            <div
                              style={{
                                position: 'absolute',
                                right: '50%',
                                transform: 'translateX(50%)',
                                fontSize: '10px',
                                bottom: '-13px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              (Имя Фамилия)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </>
      </div>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
