import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnProps,
  TableProps,
  TextField,
  Tooltip,
  useForm,
  useSelect,
  useTable,
} from '@pankod/refine-antd';

import { CalendarOutlined } from '@ant-design/icons';

import { CanAccess, useOne, useUpdate } from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { convertEnumToOptions } from 'utility/enumToArray';
import { ContactType } from 'enums/contact.type';
import moment from 'moment';

const { TextArea } = Input;

export default function ModalHistoryCompanyAssig({
  open,
  onCancel,
  uuid,
}: {
  open: boolean;
  onCancel: any;
  uuid: number;
}) {
  const { t } = useTranslation();

  const {
    tableProps,
    tableQueryResult: { isLoading },
  } = useTable({
    resource: 'companies/assig/history/',
    hasPagination: false,
    initialFilter: [{ field: 'companies.uuid', operator: 'eq', value: uuid }],
  });

  const columns: TableProps['columns'] = [
    {
      title: 'Дата заявки',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => (
        <TextField value={moment(value).format('DD.MM.YYYY')} />
      ),
    },
    {
      title: 'Заявка',
      dataIndex: 'offerNumber',
      key: 'offerNumber',
    },
    {
      title: 'Номер авто',
      dataIndex: 'carNumber',
      key: 'carNumber',
    },
    {
      title: 'Функции',
      key: 'settings',
      width: 10,
      align: 'end',
      render: (value, record) => (
        <Tooltip title="Добавить в календарь">
          <Button disabled icon={<CalendarOutlined />} />
        </Tooltip>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      width={'100%'}
      footer={null}
      style={{ maxWidth: '1000px' }}
      onCancel={onCancel}
      title={t('translation.history')}
    >
      <Spin spinning={isLoading}>
        <Table {...tableProps} columns={columns} />
      </Spin>
    </Modal>
  );
}
