import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { IUser } from 'interfaces';
import { axiosInstance } from './App';
import axios, { CancelToken } from 'axios';
import { setUserUpdateFunction } from './userUpdater';
interface IUserContext {
  user: IUser | null;
  updateUser: () => void;
  setUserFromOutside: (userData: IUser) => void;
}

export const UserContext = createContext<IUserContext | null>(null);

const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [updateCounter, setUpdateCounter] = useState<number>(0);

  const setUserFromOutside = useCallback((userData: IUser) => {
    setUser(userData);
  }, []);
  useEffect(() => {
    setUserUpdateFunction(setUserFromOutside);
  }, [setUserFromOutside]);

  const fetchUser = useCallback(
    async (cancelToken: CancelToken) => {
      try {
        axiosInstance.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const { data: me } = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/me`,
          { cancelToken },
        );

        if (JSON.stringify(me) !== JSON.stringify(user)) {
          setUser(me);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request was cancelled');
        } else {
          console.error('Failed to fetch user:', error);
        }
      }
    },
    [user],
  );

  const updateUser = useCallback(() => {
    setUpdateCounter((prevCounter) => prevCounter + 1);
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchUser(source.token);

    return () => {
      source.cancel('Component unmounted');
    };
  }, [fetchUser, updateCounter]);

  const contextValue: IUserContext = {
    user,
    updateUser,
    setUserFromOutside,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const useUser = (): IUserContext => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider78');
  }
  return context;
};

const useUserUpdate = (): (() => void) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserUpdate must be used within a UserProvider((');
  }
  return context.updateUser;
};

export { UserProvider, useUser, useUserUpdate };
