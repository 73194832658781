import { SelectProps } from '@pankod/refine-antd';

export const tags: SelectProps['options'] = [
  { value: 'AS' },
  { value: 'AT' },
  { value: 'BG' },
  { value: 'BL' },
  { value: 'BY' },
  { value: 'CZ' },
  { value: 'DE' },
  { value: 'DG' },
  { value: 'DNR' },
  { value: 'LNR' },
  { value: 'ES' },
  { value: 'EU' },
  { value: 'GE' },
  { value: 'GR' },
  { value: 'HU' },
  { value: 'IT' },
  { value: 'LT' },
  { value: 'LV' },
  { value: 'MD' },
  { value: 'NL' },
  { value: 'PL' },
  { value: 'PRB' },
  { value: 'RO' },
  { value: 'RU' },
  { value: 'UA' },
  { value: 'UZ' },
  { value: 'RS' },
  { value: 'SE' },
  { value: 'SI' },
  { value: 'SK' },
  { value: 'SL' },
  { value: 'SNG' },
  { value: 'TR' },
];
