import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useGetIdentity,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  Col,
  CreateButton,
  DateField,
  DatePicker,
  DeleteButton,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Row,
  Select,
  ShowButton,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { IPayment, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import { firmColor } from 'utility/getColor';
import moment from 'moment';
import { PaymentStatus } from 'enums/payment.status';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Firms } from 'enums/firms.type';
import ModalInfo from 'components/modals/offerInfo';

export const FirmPayList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { list } = useNavigation();
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const initialFilter: CrudFilters = [
    {
      value: [PaymentStatus.INWORK],
      field: 'status',
      operator: 'in',
    },
    {
      field: 'offer.createdAt',
      operator: 'gte',
      value: `${dayjs().format('YYYY')}-${dayjs().format('MM')}-01`,
    },
    {
      field: 'offer.createdAt',
      operator: 'lt',
      value: `${dayjs().add(1, 'months').format('YYYY')}-${dayjs()
        .add(1, 'months')
        .format('MM')}-01`,
    },
  ];

  const {
    tableProps,
    sorter,
    tableQueryResult: { refetch, data },
    setFilters,
    setPageSize,
    setCurrent,
  } = useTable<IPayment>({
    resource: 'payment/lenders',
    queryOptions: { retry: 5 },
    initialSorter: [
      {
        field: 'offer.offerNumber',
        order: 'desc',
      },
    ],
    initialPageSize: pageSizeOptions,

    initialFilter,
  });

  const { mutate: update } = useUpdate({});
  const { t } = useTranslation();

  const confirmPayment = async (value: any) => {
    update(
      {
        resource: 'payment',
        id: value.uuid,
        values: { status: PaymentStatus.DONE },
        successNotification: {
          description: t('update.titleSuccess'),
          message: t('update.textSuccess'),
          type: 'success',
        },
        errorNotification: {
          description: t('error.createText'),
          message: 'Возникла ошибка',
          type: 'error',
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const items = (value: any): MenuProps['items'] => {
    return [
      {
        label: 'Подтвердить',
        key: '1',
        icon: <CheckCircleOutlined />,
        onClick: () => {
          confirmPayment(value);
        },
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const changeFilters = (values: any) => {
    const data = form.getFieldsValue();

    let dates: CrudFilters = [
      {
        field: 'offer.createdAt',
        operator: 'gte',
        value: data.createdAt
          ? `${dayjs(data.createdAt).format('YYYY')}-${dayjs(
              data.createdAt,
            ).format('MM')}-01`
          : '',
      },
      {
        field: 'offer.createdAt',
        operator: 'lt',
        value: data.createdAt
          ? `${dayjs(data.createdAt).add(1, 'months').format('YYYY')}-${dayjs(
              dayjs(data.createdAt).add(1, 'months').toString(),
            ).format('MM')}-01`
          : '',
      },
    ];
    let finished: CrudFilters;
    if (data.finished) {
      form.setFieldValue('createdAt', null);
      finished = [
        {
          field: 'status',
          operator: 'in',
          value: [PaymentStatus.INWORK, PaymentStatus.DONE],
        },
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
      ];
      dates = [
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
      ];
    } else {
      finished = [
        {
          field: 'status',
          operator: 'in',
          value: [PaymentStatus.INWORK],
        },
      ];
    }
    if (data.offerNumber) {
      form.setFieldValue('createdAt', null);
      dates = [
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
      ];
    }

    setFilters([
      {
        field: 'offerLender.orgName',
        operator: 'contains',
        value: data.lender,
      },
      {
        field: 'offerNumber',
        operator: 'contains',
        value: data.offerNumber,
      },
      {
        field: 'offer.company',
        operator: 'eq',
        value: data.company,
      },

      ...dates,
      ...finished,
    ]);
  };

  const [form] = Form.useForm();

  const [modalContextInfo, setModalContextInfo] = useState<boolean>(false);

  return (
    <>
      {selectedUuid && modalContextInfo && (
        <ModalInfo
          selectedUuid={Number(selectedUuid)}
          open={modalContextInfo}
          onClose={() => {
            setModalContextInfo(false);
          }}
          refetch={refetch}
        />
      )}
      <List
        headerProps={{
          extra: [],
        }}
      >
        <Form
          form={form}
          initialValues={{
            createdAt: dayjs(),
            company: '',
            lender: '',
            offerNumber: '',
            finished: false,
            in_work: false,
          }}
          layout="vertical"
        >
          <Row gutter={[6, 2]}>
            <Col xs={12} md={4}>
              <Form.Item name="lender">
                <Input
                  placeholder="Заказчик"
                  size="small"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={3}>
              <Form.Item name="offerNumber">
                <Input
                  placeholder="Заявка"
                  size="small"
                  allowClear
                  onChange={changeFilters}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="createdAt">
                <DatePicker
                  size="small"
                  format={'MMMM YYYY'}
                  picker="month"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="company">
                <Select
                  size="small"
                  style={{ width: '200px' }}
                  onChange={changeFilters}
                >
                  <Select.Option value={''} children={'Показать все'} />
                  <Select.Option value={Firms.REAL} children={Firms.REAL} />
                  <Select.Option value={Firms.RCI} children={Firms.RCI} />
                  <Select.Option
                    value={Firms.RCI_INVEST}
                    children={Firms.RCI_INVEST}
                  />
                  <Select.Option
                    value={Firms.RCI_PMR}
                    children={Firms.RCI_PMR}
                  />
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="finished">
                <Switch
                  checkedChildren="Включены готовые"
                  unCheckedChildren="Готовые скрыты"
                  onChange={changeFilters}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          // onChange={(value) => {
          //   if (value.pageSize) {
          //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
          //     setPageSize(value.pageSize);
          //   }
          //   if (value.current) {
          //     setCurrent(value.current);
          //   }
          // }}
          caption={
            <Typography.Text type="secondary" style={{ padding: '4px' }}>
              {`Всего оплат : ${data?.total}`}
            </Typography.Text>
          }
          scroll={{ x: 'max-content' }}
          size="small"
        >
          <Table.Column
            dataIndex="vamaLender"
            key="vamaLender"
            title="Заказчик"
            align="center"
            render={(value) => (
              <TextField value={value.offer.offerLender.orgName} />
            )}
          />
          <Table.Column
            dataIndex="vamaLender"
            key="vamaLender"
            title="Заявка"
            align="center"
            fixed="left"
            render={(value) => (
              <Tooltip title={value.offer.company}>
                <Tag
                  color={firmColor(value.offer.company)}
                  style={{
                    cursor: 'pointer',
                    width: '50px',
                    textAlign: 'center',
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setSelectedUuid(value.uuid);
                    setModalContextInfo(true);
                  }}
                  onClick={() => {
                    list(`blanks/offers?uuid=${value.offer.uuid}`);
                  }}
                >
                  {value.offer.offerNumber}
                </Tag>
              </Tooltip>
            )}
          />
          <Table.Column
            dataIndex="vamaLender"
            key="vamaLender"
            title="Сумма(лей)"
            align="right"
            render={(value) => (
              <TextField
                value={Number(
                  +value.cursLender * +value.offer.priceFirm,
                ).toLocaleString('ru-Ru', { maximumFractionDigits: 2 })}
              />
            )}
          />
          <Table.Column
            dataIndex="vamaLender"
            key="vamaLender"
            title="Растаможка"
            align="center"
            render={(value) => (
              <TextField
                value={moment(value.datePayment).format('DD.MM.YYYY')}
              />
            )}
          />
          <Table.Column
            dataIndex="vamaLender"
            key="vamaLender"
            title="Опл. заказчик"
            align="left"
            render={(value) => (
              <TextField
                value={`${moment(value.datePayment)
                  .add(+value.offer.firmFrom, 'days')
                  .format('DD.MM.YYYY')} ${
                  value.offer.firmFrom !== value.offer.firmTo
                    ? `- ${moment(value.datePayment)
                        .add(+value.offer.firmTo, 'days')
                        .format('DD.MM.YYYY')}`
                    : ''
                }`}
              />
            )}
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Статус"
            align="right"
            render={(value) => {
              switch (value) {
                case PaymentStatus.NEW:
                  return (
                    <Tag color="warning" icon={<QuestionCircleOutlined />}>
                      {value}
                    </Tag>
                  );
                case PaymentStatus.INWORK:
                  return (
                    <Tag color="blue" icon={<LoadingOutlined />}>
                      {value}
                    </Tag>
                  );
                case PaymentStatus.DONE:
                  return (
                    <Tag color="green" icon={<CheckCircleOutlined />}>
                      {value}
                    </Tag>
                  );
                default:
                  return <Tag icon={<UserOutlined />}>{value}</Tag>;
              }
            }}
          />
          <Table.Column
            dataIndex="user"
            key="user"
            title="RCI"
            width={80}
            align="center"
            render={(value) =>
              value && value.pictures?.path ? (
                <Tooltip
                  title={`${value.lastName} ${value.firstName}`}
                  placement="left"
                >
                  <Avatar
                    style={{ width: '50px', height: '50px' }}
                    src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures.path}`}
                    alt={value.lastName}
                  />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />

          <Table.Column
            key="action"
            title=""
            fixed="right"
            width={20}
            render={(_, value: any) => (
              <>
                <CanAccess resource="payment_lender" action="confirm">
                  <Dropdown
                    menu={menuProps(value)}
                    arrow
                    trigger={['click']}
                    placement="bottomLeft"
                    destroyPopupOnHide
                  >
                    <Button
                      icon={<EllipsisOutlined />}
                      shape="circle"
                      type="dashed"
                    />
                  </Dropdown>
                </CanAccess>
              </>
            )}
          />
        </Table>
      </List>
    </>
  );
};
