import React from 'react';
import { TitleProps } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  // <div style={{height: 70}}></div>
  <Link to="/offers">
    {collapsed ? (
      <img
        src={'/refine-collapsed.svg'}
        alt="Refine"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px 24px',
        }}
      />
    ) : (
      <img
        src={'/refine.svg'}
        alt="Refine"
        style={{
          width: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px 24px',
          marginLeft: 22,
          marginTop: 11,
        }}
      />
    )}
  </Link>
);
