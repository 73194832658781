import React, { useState } from 'react';
import { useNotification } from '@pankod/refine-core';
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from '@pankod/refine-antd';
import './styles.css';
import { AxiosError, AxiosResponse } from 'axios';
import './styles.css';
import { axiosInstance } from 'App';
const { Title } = Typography;
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
export interface IRecoverForm {
  token?: string;
  password?: string;
  confirmPassword?: string;
}
export const Recover: React.FC = () => {
  const { open: notify } = useNotification();
  const [sendDisabled, setSendDisabled] = useState(false);
  const [form] = Form.useForm<IRecoverForm>();
  const CardTitle = (
    <Title level={3} className="title">
      Восстановление пароля
    </Title>
  );
  const onSendForm = ({ password }: IRecoverForm) => {
    if (notify) {
      setSendDisabled(true);
      axiosInstance
        .post(process.env.REACT_APP_BACKEND_URL + '/api/v1/auth/recover', {
          email: params.email,
          token: params.token,
          password,
        })
        .then((response: AxiosResponse) => {
          notify({
            description: 'Пароль успешно изменен',
            message: 'Перенаправляем Вас на форму входа в систему',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
          setTimeout(() => {
            window.location.replace(
              process.env.REACT_APP_BACKEND_URL + '/login?recovered=1',
            );
          }, 5000);
        })
        .catch((error) => {
          if (error.message.indexOf('401') !== -1) {
            notify({
              description: 'Неправильный token',
              message: 'Пройдите по ссылке из email',
              type: 'error',
              key: '2',
              undoableTimeout: 20000,
            });
          }
        });
    }
  };
  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          background:
            'radial-gradient(50% 50% at 50% 50%, #534777 0%, #111824 100%)',
          backgroundSize: 'cover',
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="./refine.svg" alt="Qliq Logo" width="150" />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IRecoverForm>
                layout="vertical"
                form={form}
                onFinish={onSendForm}
                requiredMark={false}
              >
                <Form.Item
                  name="password"
                  label="Введите новый пароль"
                  rules={[
                    {
                      required: true,
                      min: 6,
                      max: 20,
                      message:
                        'Пожалуйста, введите пароль (длина от 6 до 20 символов)',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  label="Повторите новый пароль"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, повторите ввод пароля',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Введенные пароли не совпадают'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  disabled={sendDisabled}
                  block
                >
                  Сохранить пароль
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
