import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';
import App from './App';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import locale from 'antd/locale/ru_RU';
import './i18n';
import { Spin } from '@pankod/refine-antd';
import './sass/app.scss';
ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={<Spin style={{ padding: '200px', margin: '0 auto' }} />}
    >
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: '#154468',
            colorSuccess: '#7da879',
            colorError: '#dc5e5e',
            colorWarning: '#faad14',
            colorInfo: '#7e90c0',
            borderRadius: 4,
            sizeStep: 4,
          },
        }}
      >
        <App />
      </ConfigProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
