import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  useForm,
} from '@pankod/refine-antd';
import {
  CanAccess,
  useNotification,
  useOne,
  useSelect,
  useUpdate,
} from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from 'App';
import { UserType } from 'enums/user.type';
export default function ModalEditUser({
  open,
  onCancel,
  refetch,
  uuid,
  array,
}: {
  open: boolean;
  refetch: any;
  onCancel: any;
  uuid: number;
  array?: React.Key[];
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { options: optionUser } = useSelect<IUser[]>({
    resource: 'users/get/userList',
    optionLabel: 'label',
    optionValue: 'value',
    liveMode: 'auto',
    hasPagination: false,
  });
  const options: any[] = optionUser;

  const { mutate } = useUpdate({});
  const { open: notify } = useNotification();

  const saveBtn = async (value: any) => {
    const dataToSend = { userUuid: value.user, array: array };
    if (array && array?.length > 0) {
      if (notify) {
        try {
          const res = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/companies/setArray',
            { user: value.user, data: array, type: Role.MANAGER },
          );

          notify({
            description: 'Удачно',
            message: 'Компании установлены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });

          refetch();
          onCancel();
        } catch (e: any) {
          notify({
            description: 'Ошибка',
            message: 'Неудалось сохранить данные',
            type: 'error',
            key: '2',
            undoableTimeout: 20000,
          });
        }
      }
    } else {
      mutate(
        {
          resource: 'companies/updateUser',
          id: uuid,
          values: dataToSend,
          successNotification: {
            description: t('update.titleSuccess'),
            message: t('update.textSuccess'),
            type: 'success',
          },
          errorNotification: {
            description: t('error.createText'),
            message: 'Возникла ошибка',
            type: 'error',
          },
        },
        {
          onSuccess: () => {
            refetch();
            onCancel();
          },
        },
      );
    }
  };

  return (
    <Modal
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      title={t('changeUser.modal.title')}
    >
      <Form form={form} onFinish={saveBtn} requiredMark>
        <Form.Item
          label={t('changeUser.modal.select')}
          rules={[
            {
              required: true,
            },
          ]}
          name="user"
        >
          <Select
            showSearch
            defaultValue={null}
            size="small"
            onChange={(value) => console.log('user', value)}
            options={[...options]}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toUpperCase()
                .includes(input.toLocaleUpperCase())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
